import clsx from "clsx";
import classes from "./ProjectTaskOrderlineStatusTemplate.module.scss";
import ProjectTaskStatus from "../../enums/project-task-status";
import { Dropdown } from "primereact/dropdown";
import { useState, useEffect, useRef } from "react";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import useToastr from "../../../../../hooks/useToastr";

import OnHoldReasonDialog from "../onhold-reason-dialog/OnHoldReasonDialog";
import ProjectService from "../../../../../services/ProjectService";
import PmrE2EOnHoldReason from "../../interfaces/pmr-e2e-project-onhold-reason";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModalV2 from "../../../../../shared/confirmation-modal-v2/ConfirmationModalV2";

interface IProjectTaskStatus {
  name: string;
  code: number;
  disabled: boolean;
  tooltipMessage: string;
}
interface ProjectTaskOrderlineStatusTemplateProps {
  status: ProjectTaskStatus;
  tooltip?: string;
  orderlineNumber?: string;
  handleOrderlineStatusChange: any;
  E2EProjectLineId: string;
  E2EProjectLineFlexTaskId: string;
  isLoading?: boolean;
  containerStyle?: React.CSSProperties | undefined;
  totalHours: number;
}
const ProjectTaskOrderlineStatusTemplate = (
  props: ProjectTaskOrderlineStatusTemplateProps
) => {
  const statusOptionsRef = useRef<HTMLDivElement>(null);
  const [isEditStatus, setIsEditStatus] = useState(false);
  const [activeOptions, setActiveOptions] = useState<IProjectTaskStatus[]>([
    // {
    //   name: "Not Scheduled",
    //   code: 0,
    //   disabled: false,
    //   tooltipMessage: "",
    // },
    {
      name: "In Progress",
      code: 200,
      disabled: false,
      tooltipMessage:
        "This task will only progress once its Start Date commences.",
    },
    { name: "On Hold", code: 300, disabled: false, tooltipMessage: "" },
    {
      name: "Cancelled",
      code: 500,
      disabled: false,
      tooltipMessage: "Tasks with logged work hours cannot be cancelled.",
    },
    // {
    //   name: "Completed",
    //   code: 400,
    //   disabled: true,
    //   tooltipMessage:
    //     "You can only timestamp ongoing tasks to mark it as Completed.",
    // },
  ]);

  const { showInfo } = useToastr();
  const [currentStatus, setCurrentStatus] = useState<IProjectTaskStatus>();

  const [updatedStatus, setUpdatedStatus] = useState<IProjectTaskStatus>();
  const [isOnHoldReasonVisible, setIsOnHoldReasonVisible] = useState(false);
  const [selectedOnHoldReason, setSelectedOnHoldReason] =
    useState<PmrE2EOnHoldReason>();

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const [isCancelConfirmationVisible, setIsCancelConfirmationVisible] =
    useState(false);

  const onClosCancelConfirmation = () => {
    setIsCancelConfirmationVisible(false);
    setIsEditStatus(false);
  };

  const onCancelOnHoldReason = () => {
    setIsOnHoldReasonVisible(false);
    setIsEditStatus(false);

    showInfo(
      "Status change cancelled",
      "The Task remains in its previous state."
    );
  };

  const onStatusChange = async (e: any) => {
    setCurrentStatus(e.value);
    setUpdatedStatus(e.value);
  };

  const handleUpdateStatus = async () => {
    setIsSaveLoading(true);
    const taskToUpdate = {
      E2EProjectLineId: props.E2EProjectLineId,
      E2EProjectLineFlexTaskId: props.E2EProjectLineFlexTaskId,
      Status: updatedStatus?.code,
      TaskStatusDetailId: selectedOnHoldReason?.taskStatusDetailId,
      TaskStatusDetail: selectedOnHoldReason?.taskStatusReason,
    };

    const result = await ProjectService.updateOrderlineFlexTaskStatus(
      taskToUpdate
    );

    if (result.isSuccess) {
      props.handleOrderlineStatusChange(
        selectedOnHoldReason,
        result.message,
        result.data
      );

      handleStatusOptions();

      setIsOnHoldReasonVisible(false);
      setIsEditStatus(false);
      setCurrentStatus(undefined);
      setUpdatedStatus(undefined);
      setSelectedOnHoldReason(undefined);
      setIsSaveLoading(false);
    }
  };

  const handleStatusChange = async () => {
    if (updatedStatus?.code === ProjectTaskStatus.OnHold) {
      setIsEditStatus(true);
      setIsOnHoldReasonVisible(true);
    } else if (updatedStatus?.code === ProjectTaskStatus.Cancelled) {
      setIsCancelConfirmationVisible(true);
    } else {
      await handleUpdateStatus();
    }
  };

  const getDisplay = () => {
    switch (props.status) {
      case ProjectTaskStatus.NotStarted:
      case ProjectTaskStatus.AwaitingAssignment:
      case ProjectTaskStatus.NotScheduled:
        return "Not Scheduled";
      case ProjectTaskStatus.InProgress:
        return "In Progress";
      case ProjectTaskStatus.OnHold:
        return "On Hold";
      case ProjectTaskStatus.Cancelled:
        return "Cancelled";
      case ProjectTaskStatus.Completed:
        return "Completed";
      default:
        return "Not defined";
    }
  };

  const getClass = () => {
    switch (props.status) {
      case ProjectTaskStatus.NotStarted:
      case ProjectTaskStatus.AwaitingAssignment:
      case ProjectTaskStatus.NotScheduled:
        return classes["notscheduled"];
      case ProjectTaskStatus.InProgress:
        return classes["inprogress"];
      case ProjectTaskStatus.OnHold:
        return classes["onhold"];
      case ProjectTaskStatus.Cancelled:
        return classes["cancelled"];
      case ProjectTaskStatus.Completed:
        return classes["completed"];
      default:
        return "disabled";
    }
  };

  const statusOptionTemplate = (option: IProjectTaskStatus) => {
    if (option.code == currentStatus?.code) {
      option.tooltipMessage = "This is the current status.";
    } else if (option.code === ProjectTaskStatus.Cancelled && !option.disabled)
      option.tooltipMessage = "";

    return (
      <div
        className={`status-${option.code} ${option.disabled ? "disabled" : ""}`}
      >
        <span style={{ pointerEvents: "auto" }}>{option.name}</span>
        <Tooltip
          target={`.status-${option.code}`}
          content={option.tooltipMessage}
        />
      </div>
    );
  };

  const cancelBodyTemplate = (
    <>
      Confirming wil permanently remove this task from the order line's project
      lifecycle.
      <br /> <br />
      Proceed?
    </>
  );

  const cancelFooterTemplate = (
    <div className={classes["footer"]}>
      <Button
        onClick={() => handleConfirmCancellation(false)}
        className={classes["button-cancel"]}
      >
        CANCEL
      </Button>
      <Button
        onClick={() => handleConfirmCancellation(true)}
        className={classes["button-save"]}
      >
        CONFIRM
      </Button>
    </div>
  );

  const handleConfirmCancellation = (isCancel: boolean) => {
    if (isCancel) handleUpdateStatus();

    setIsEditStatus(false);
    setIsCancelConfirmationVisible(false);
  };

  const handleStatusOptions = () => {
    switch (currentStatus?.code) {
      case ProjectTaskStatus.NotScheduled:
        return setActiveOptions((prev) => {
          const newList = structuredClone(prev);

          newList.forEach((task: IProjectTaskStatus) => {
            if (
              task.code === ProjectTaskStatus.InProgress ||
              (task.code === ProjectTaskStatus.Cancelled &&
                props.totalHours != 0)
            )
              task.disabled = true;
          });
          return newList;
        });

      case ProjectTaskStatus.InProgress:
        return setActiveOptions((prev) => {
          const newList = structuredClone(prev);

          newList.forEach((task: IProjectTaskStatus) => {
            if (
              task.code === ProjectTaskStatus.Cancelled &&
              props.totalHours != 0
            )
              task.disabled = true;

            if (task.code === ProjectTaskStatus.NotScheduled) {
              task.disabled = true;
              task.tooltipMessage = "This task has already commenced.";
            }
          });
          return newList;
        });

      case ProjectTaskStatus.OnHold:
        return setActiveOptions((prev) => {
          const newList = structuredClone(prev);

          newList.forEach((task: IProjectTaskStatus) => {
            if (
              task.code === ProjectTaskStatus.NotScheduled ||
              (task.code === ProjectTaskStatus.Cancelled &&
                props.totalHours != 0)
            )
              task.disabled = true;
          });
          return newList;
        });

      default:
        return setActiveOptions((prev) =>
          prev.map((status) => ({ ...status, disabled: false }))
        );
    }
  };

  const handleStatusClick = (status: ProjectTaskStatus) => {
    if (
      status === ProjectTaskStatus.Cancelled ||
      status === ProjectTaskStatus.Completed
    )
      return;

    setCurrentStatus(activeOptions.find((data) => data.code === status));
    setIsEditStatus(true);
  };

  const handleOutsideClick = (event: any) => {
    if (
      updatedStatus?.code !== 300 &&
      updatedStatus?.code !== 500 &&
      statusOptionsRef.current &&
      !statusOptionsRef.current.contains(event.target)
    ) {
      setIsEditStatus(false);
    }
  };

  useEffect(() => {
    if (updatedStatus?.code) handleStatusChange();
  }, [updatedStatus]);

  useEffect(() => {
    if (currentStatus) handleStatusOptions();
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [currentStatus]);

  const loadingIndicator = (
    <div className="text-center w-100">
      <FontAwesomeIcon icon={faSpinner} spin={true} />
    </div>
  );

  if (props.isLoading) return loadingIndicator;

  return (
    <div style={props.containerStyle}>
      <ConfirmationModalV2
        className={classes["cancel-modal"]}
        style={{ width: "30vw" }}
        visible={isCancelConfirmationVisible}
        onClose={onClosCancelConfirmation}
        headerDisplay="Confirm Task Cancellation"
        confirmationMessageHTML={cancelBodyTemplate}
        customFooter={cancelFooterTemplate}
      ></ConfirmationModalV2>

      <OnHoldReasonDialog
        visible={isOnHoldReasonVisible}
        onClose={onCancelOnHoldReason}
        setSelectedOnHoldReason={setSelectedOnHoldReason}
        handleSaveOnHoldReason={handleUpdateStatus}
        isSaveLoading={isSaveLoading}
      />

      {isEditStatus ? (
        <span ref={statusOptionsRef}>
          <Dropdown
            value={currentStatus}
            options={activeOptions}
            onChange={onStatusChange}
            optionLabel="name"
            className={classes["custom-dropdown"]}
            style={{ fontSize: "20px" }}
            optionDisabled="disabled"
            itemTemplate={statusOptionTemplate}
          />
        </span>
      ) : (
        <span
          title={props.tooltip}
          className={clsx(classes["custom-badge"], getClass())}
          onClick={() => handleStatusClick(props.status)}
        >
          {getDisplay()}
        </span>
      )}
    </div>
  );
};
export default ProjectTaskOrderlineStatusTemplate;
