import {
  faEdit,
  faSpinner,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosError } from "axios";
import { get, uniqBy } from "lodash";
import { Button } from "primereact/button";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable, DataTablePFSEvent } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ColumnResizer from "../../components/column-resizer/ColumnResizer";
import axios from "axios";
import {
  hasSelectedE2EProjectId,
  resetInitialProjectLines,
  resetProjectLines,
  selectApplicant,
  selectInitialE2EProjectLines,
  selectProjectLines,
  selectSelectFavoritesModel,
  updateApplicant,
  updateCustomer,
  updateCustomerContact,
  updateCustomerLocation,
  updateE2EProjectId,
  updateE2EProjectList,
  updateE2EProjectName,
  updateFavoritesModel,
  updateInitialE2EProjectLines,
  updateProductOwner,
  updateProjectLines,
} from "../../features/projectWorkQueue/projectWorkQueueSlice";
import usePrimeReactDataTable from "../../hooks/usePrimeReactDataTable";
import usePwqLazyLoadDataTable from "../../hooks/usePwqLazyLoadDataTable";
import { useSignalR } from "../../hooks/useSignalR";
import useToastr from "../../hooks/useToastr";
import { E2EUserModel } from "../../models/E2EUserModel";
import { AssignFlexHandlerModel } from "../../models/flex/AssignFlexHandler.model.";
import {
  AssignFlexHandlerServiceBusEntry,
  AssignFlexHandlerStatus,
} from "../../models/flex/AssignFlexHandlerStatus.model";
import { FlexProjectServiceLineModel } from "../../models/flex/FlexProjectServiceLine.model";
import { ProjectWorkQueueRefinersModel } from "../../models/project-work-queue/ProjectWorkQueueRefiners.model";
import FlexIntegrationService from "../../services/FlexIntegrationService";
import ProjectService from "../../services/ProjectService";
import ReferenceService from "../../services/ReferenceService";
import { formatDateWithTimezone } from "../../shared/date-helper/DateHelper";
import FavoriteTypesEnum from "../../shared/favorites/FavoriteTypesEnum";
import Favorites from "../../shared/favorites/Favorites";
import { CustomerModel } from "../../shared/models/Customer.model";
import {
  E2EFlexProjectModel,
  E2EProjectLineModel,
} from "../../shared/models/E2EProject.model";
import { GetDeliveryPathByCatalogResultModel } from "../../shared/models/service-models/GetDeliveryPathByCatalogResult.model";
import ShowHideColumnsPrimeDataTable from "../../shared/show-hide-columns-prime-datatable/ShowHideColumnsPrimeDataTable";
import { flexProjectContactRole } from "../../utils/constants/flex-project-contact-role.constants";
import { getCsvFormatWithoutQuotes } from "../../utils/helpers/array.helpers";
import { isValidNumber } from "../../utils/helpers/object.helpers";
import {
  GridColumnModel,
  GridColumnModelList,
  pWqColumnProjectHandler,
  projectWorkQueueGridColumns,
} from "../project-management/data/project-work-queue-grid-columns";
import classes from "./ProjectWorkQueue.module.scss";
import AssignFlexHandlerCell from "./assign-flex-handler-cell/AssignFlexHandlerCell";
import AssignFlexHandlerModal from "./assign-flex-handler-modal/AssignFlexHandlerModal";
import ProjectAlreadyExistsModal from "./project-already-exists-modal/ProjectAlreadyExistsModal";
import UnsupportedFlexTemplateModal, {
  UnsupportedFlexTemplateDataProps,
} from "./unsupported-flex-template-modal/UnsupportedFlexTemplateModal";
import ProjectWorkQueueModal from "./project-work-queue-modal/ProjectWorkQueueModal";
import ProjectWorkQueueRefiners from "./project-work-queue-refiners/ProjectWorkQueueRefiners";
import * as _ from "lodash";
import ProjectOrderlineStatus from "../project-management-pmr/shared/enums/project-orderline-status";
import ProjectStatus from "../project-management-pmr/shared/enums/project-status";
import { FlexUnsupportedTemplateListModel } from "../../shared/models/FlexProjectTemplateTaskDetail.model";
import ProjectCreationRestrictionModal from "./project-creation-restriction-modal/ProjectCreationRestrictionModal";
import { ProjectCreationRestrictionModel } from "./project-creation-restriction-modal/ProjectCreationRestrictionModel";
import clsx from "clsx";
import ActionsColumnBody from "./actions-column-body/ActionsColumnBody";

const ProjectWorkQueue = () => {
  var dispatch = useDispatch();
  const clv = useRef<DataTable>(null);
  const e2eProjectLines = useSelector(selectProjectLines);
  const initialProjectLines = useSelector(selectInitialE2EProjectLines);
  const e2eApplicant = useSelector(selectApplicant);
  const selectFavoritesModel = useSelector(selectSelectFavoritesModel);
  const [favoritTypeId, setFavoriteTypeId] = useState(
    FavoriteTypesEnum.ProjectWorkQueue
  );
  const [pwqFrozenCount, setpwqFrozenCount] = useState(1);
  const [columns, setColumns] = useState(projectWorkQueueGridColumns);
  const [customSearchText, setCustomSearchText] = useState("");
  const [isCreateProjectModalVisible, setCreateProjectModalVisible] =
    useState(false);
  const [certificateSchemeList, setCertificateSchemeList] = useState<any>(null);
  const [fetchProjectLineCancelToken, setFetchProjectLineCancelToken] =
    useState<any>(undefined);
  const [projectChildLines, setProjectChildLines] = useState<
    FlexProjectServiceLineModel[]
  >([]);

  const [isLoadingProjectHandlers, setIsLoadingProjectHandlers] =
    useState(true);

  const [isCdpSaved, setIsCdpSaved] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);

  const [selectedProjectServiceLineIds, setSelectedProjectServiceLineIds] =
    useState<string[]>([]);

  const [assignFlexHandlerStatuses, setAssignFlexHandlerStatuses] = useState<
    AssignFlexHandlerServiceBusEntry[]
  >([]);

  const [projectHandlers, setProjectHandlers] = useState<E2EUserModel[]>([]);

  const [showAssignFlexHandlerModal, setShowAssignFlexHandlerModal] =
    useState(false);

  const [
    showFlexUnsupportedTemplateListModal,
    setShowFlexUnsupportedTemplateListModal,
  ] = useState(false);

  const [
    isProjectAlreadyExistsModalVisible,
    setIsProjectAlreadyExistsModalVisible,
  ] = useState(false);
  const hubUrl = (process.env.REACT_APP_GMAE2E_FUNC_URL + "/api") as string;
  const { connection: signalRConnection } = useSignalR(hubUrl);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const [projectIdsQueryParams, setProjectIdsQueryParams] = useState<string[]>(
    params?.projectIds?.split(",") || []
  );

  const [flexUnsupportedTemplateList, setFlexUnsupportedTemplateList] =
    useState<FlexUnsupportedTemplateListModel[]>([]);

  const [unsupportedTemplateDataProp, setUnsupportedTemplateDataProp] =
    useState<UnsupportedFlexTemplateDataProps | null>(null);

  const [
    assignFlexHandlerStatusNewMessage,
    setAssignFlexHandlerStatusNewMessage,
  ] = useState<any>();

  const { showSuccess, showError } = useToastr();
  const [isAddOrderLine, setIsAddOrderLine] = useState(false);
  const [existingProjectLine, setExistingProjectLine] = useState<string[]>([]);
  const [
    showRestrictCreationMessageModal,
    setShowRestrictCreationMessageModal,
  ] = useState<boolean>(false);
  const hasCustomQueryRefiner = !!params?.projectIds;

  const defaultRefiners = hasCustomQueryRefiner
    ? {}
    : {
        status: [
          { label: "In Progress", value: "InProgress" },
          { label: "On Hold", value: "OnHold" },
        ],
      };
  const [selectedRefiners, setSelectedRefiners] = useState(defaultRefiners);
  const [initialGlobalSearch, setInitialGlobalSearch] = useState("");
  const [initialRefiners, setInitialRefiners] = useState({});
  const [disabledRefiners, setDisabledRefiners] = useState({});
  const [projectCreationRestrictions, setProjectCreationRestrictions] =
    useState<ProjectCreationRestrictionModel>({});

  const hasSelectedProjectId = useSelector(hasSelectedE2EProjectId);
  const [addOrderLineDefaultRefiners, setAddOrderLineDefaultRefiners] =
    useState({});

  const [selectedAccountNo, setSelectedAccountNo] = useState<string>("");
  const [selectedPartySiteNumber, setPartySiteNumber] = useState<string>("");

  const initialSortField = "project.createdOn";
  const initialSortOrder = "desc";
  const initialItemsPerPage = 30;
  const [favoriteTooltip, setFavoriteToolTip] = useState("");

  const [isFavoriteRefinersLoaded, setIsFavoriteRefinersLoaded] =
    useState(false);
  const getProjectLineListCancelSource = useRef<any>(null);

  const { databaseKey, fixedColumnResizedFunction } = usePrimeReactDataTable(
    clv,
    columns
  );

  const {
    handleSort,
    sortField,
    sortOrder,
    itemsPerPage,
    isLazyLoading,
    setIsLazyLoading,
    isLoading,
    setIsLoading,
    getSetPageNo,
    setTotalRecords,
    showLoadMore,
    recordsShownCount,
    setRecordsShownCount,
    loadListDebounce,
    resetScroll,
    defaultSortField,
    defaultSortOrder,
    setSortField,
    setSortOrder,
    loadedList,
    isBcpMode,
    setIsBcpMode,
    cacheStartingPage,
    setCacheStartingPage,
    cacheEndingPage,
    setCacheEndingPage,
  } = usePwqLazyLoadDataTable(
    initialItemsPerPage,
    initialSortField,
    clv,
    loadProjectChildLines,
    customSearchText,
    selectedRefiners,
    [e2eProjectLines],
    isLoadMore,
    sortProjectChildLines
  );

  useEffect(() => {
    if (signalRConnection) {
      signalRConnection.on(
        "assign-flex-handler",
        setAssignFlexHandlerStatusNewMessage
      );

      signalRConnection.on(
        "assign-flex-handler-all-finished",
        handleAssignFlexHandlerAllFinished
      );
    }
  }, [signalRConnection]);

  useEffect(() => {
    if (assignFlexHandlerStatusNewMessage) {
      handleAssignFlexHandlerStatusUpdate(assignFlexHandlerStatusNewMessage);
    }
  }, [assignFlexHandlerStatusNewMessage]);

  useEffect(() => {
    loadProjectHandlers();
    loadCertificateSchemeData();
    dispatch(
      updateFavoritesModel({
        property: "columns",
        value: columns,
      })
    );
  }, columns);

  useEffect(() => {
    dispatch(
      updateFavoritesModel({
        property: "frozenCount",
        value: pwqFrozenCount,
      })
    );
  }, [pwqFrozenCount]);

  useEffect(() => {
    dispatch(
      updateFavoritesModel({
        property: "refiner",
        value: selectedRefiners,
      })
    );
  }, [selectedRefiners]);

  useEffect(() => {
    dispatch(
      updateFavoritesModel({
        property: "customSearchText",
        value: customSearchText,
      })
    );
  }, [customSearchText]);

  useEffect(() => {
    dispatch(
      updateFavoritesModel({
        property: "sort",
        value: { sortField: sortField, sortOrder: sortOrder },
      })
    );
  }, [sortField, sortOrder]);

  useEffect(() => {
    if (!isAddOrderLine) return;

    const disabledRefiner = {
      field: "flexCompanyName",
      value: [
        {
          label: `${e2eApplicant?.partySiteNumber} - ${e2eApplicant?.companyName}`,
        },
      ],
    };

    const applicantSoldToValue = {
      partyName: e2eApplicant?.companyName,
      partySiteNumber: e2eApplicant?.partySiteNumber,
    };

    const refiners = {
      status: [
        { label: "In Progress", value: "InProgress" },
        { label: "On Hold", value: "OnHold" },
      ],
      flexCompanyName: [
        {
          label: `${e2eApplicant?.partySiteNumber} - ${e2eApplicant?.companyName}`,
          value: applicantSoldToValue,
        },
      ],
    };

    const customSearchText = "";

    setIsLoadMore(false);
    setAddOrderLineDefaultRefiners(refiners);
    setCustomSearchText(customSearchText);
    setSelectedRefiners(refiners);
    setDisabledRefiners(disabledRefiner);
    loadListDebounce(true, customSearchText, refiners, sortField, sortOrder);
  }, [isAddOrderLine]);

  useEffect(() => {
    if (!isCreateProjectModalVisible && isCdpSaved) {
      loadListDebounce(
        true,
        customSearchText,
        selectedRefiners,
        sortField,
        sortOrder
      );
    }
  }, [isCreateProjectModalVisible]);

  useEffect(() => {
    if (isCreateProjectModalVisible || isAddOrderLine) {
      const handleReloadEvent = (event: any) => {
        if (
          JSON.stringify(e2eProjectLines) !==
          JSON.stringify(initialProjectLines)
        ) {
          event.preventDefault();
          event.returnValue = false;
        }
      };

      const handlePopState = (event: any) => {
        if (
          JSON.stringify(e2eProjectLines) !==
          JSON.stringify(initialProjectLines)
        ) {
          event.preventDefault();
          event.returnValue = false;
        }
      };

      window.addEventListener("beforeunload", handleReloadEvent);
      window.addEventListener("popstate", handlePopState);

      return () => {
        window.removeEventListener("beforeunload", handleReloadEvent);
        window.removeEventListener("popstate", handleReloadEvent);
      };
    }
  }, [e2eProjectLines]);

  useEffect(() => {
    if (!isFavoriteRefinersLoaded) return;

    loadProjectChildLines(
      true,
      customSearchText,
      selectedRefiners,
      sortField,
      sortOrder,
      0,
      0
    );
  }, [isFavoriteRefinersLoaded]);

  const loadProjectHandlers = async () => {
    setIsLoadingProjectHandlers(true);
    const { data } = await FlexIntegrationService.getProjectHandlers();
    setProjectHandlers(data);
    setIsLoadingProjectHandlers(false);
  };

  const loadCertificateSchemeData = async () => {
    await ReferenceService.getCertificateSchemeList()
      .then((response) => {
        setCertificateSchemeList(response.data);

        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const generateFiltersFromRefiners = (refiners: any): any => {
    const refinersToBeJoin = [
      "status",
      "flexHandler",
      "orderCreator",
      "preferredFulfillmentLocation",
      "orderNumber",
      "projectNumber",
      "partySiteNumber",
    ];
    const refinersToBeStringified = ["flexCompanyName"];

    let renderedRefiners: ProjectWorkQueueRefinersModel = {};

    Object.keys(refiners).forEach((key) => {
      let refiner = refiners[key];
      // api current behavior: passing refiners with empty string will be filtered resulting to no result
      let filterValue = undefined;
      const coalescedRefiner = refiner ?? [];

      if (refinersToBeJoin.some((refinerKey) => refinerKey === key)) {
        const joinedValue = coalescedRefiner
          .map((optionValue: any) => optionValue.value ?? optionValue.label) // adding value in option will result to strings of array
          .join("|");

        if (joinedValue.trim() !== "") filterValue = joinedValue;
      } else if (
        refinersToBeStringified.some((refinerKey) => refinerKey === key) &&
        coalescedRefiner.length > 0
      ) {
        const stringifiedValue = JSON.stringify(
          coalescedRefiner.map((optionValue: any) => optionValue.value)
        );
        if (stringifiedValue.trim() !== "") filterValue = stringifiedValue;
      } else {
        const refinerKeys = Object.keys(refiner);

        const refinerWithValue = refinerKeys.filter((refinerKey) => {
          const refinerChild = refiner[refinerKey];
          return isValidNumber(refinerChild) || !!refinerChild;
        });

        if (refinerWithValue.length > 0) {
          const finalValue: any = {};
          refinerWithValue.forEach((refinerKey) => {
            const refinerChild = refiner[refinerKey];
            finalValue[refinerKey] = refinerChild;
          });
          filterValue = finalValue;
        }
      }

      renderedRefiners[key] = filterValue;
    });

    if (projectIdsQueryParams.length > 0) {
      renderedRefiners.projectIds = projectIdsQueryParams.join("|");
    }

    return renderedRefiners;
  };

  const getDateOperator = (dateType: string) => {
    switch (dateType) {
      case "DateOn":
        return "eq";
      case "OnOrBefore":
      case "DateTo":
        return "le";
      case "OnOrAfter":
      case "DateFrom":
        return "ge";
      case "PastDue":
        return "lt";
      default:
        throw new Error(`Unknown date type: ${dateType}`);
    }
  };

  const getNumericOperator = (numericType: string) => {
    if (numericType === "GreaterThan") return "gt";
    if (numericType === "LessThan") return "lt";
    if (numericType === "EqualTo") return "eq";
  };

  const handleAssignFlexHandlerAllFinished = (data: any[]) => {
    if (data?.some((x) => x.Status === "FAILED")) {
      const allFailed = !data?.some((x) => x.Status === "SUCCESS");
      const message = allFailed
        ? "Assigning of FLEX Handler to selected Order Line(s) failed. Please try again. If error persists, please contact GMA Support team."
        : "Assigning of FLEX Handler to some selected Order Line(s) failed. Please check selected records then try again. If error persists, please contact GMA Support team.";

      showError("Error", message);

      const failedProjectIds = data.map((x) => x.ProjectId);

      setAssignFlexHandlerStatus(failedProjectIds, "FAILED");
    } else {
      showSuccess(
        "Success!",
        "FLEX Handler updated for the selected record(s)."
      );
    }
  };

  const handleAssignFlexHandlerStatusUpdate = (data: any) => {
    setAssignFlexHandlerStatus(
      [data.ProjectId],
      data.Status,
      data.NewHandlerEmail
    );

    if (data.Status === "SUCCESS") {
      setProjectChildLines((curr) =>
        curr.map((x) =>
          x.projectId === data.ProjectId
            ? {
                ...x,
                project: {
                  ...x.project,
                  projectHandler: data.NewHandlerEmail,
                },
              }
            : x
        )
      );
    }
  };

  const getQueryParams = (
    skip: number,
    pageSize: number,
    searchPhrase: string,
    refiners: any,
    sorting: string,
    cacheStartingPage: number,
    cacheEndingPage: number
  ) => {
    const filter = {
      globalSearch: searchPhrase?.trim() || "",
      numberOfRecords: pageSize,
      skipRecords: skip,
      refiner: generateFiltersFromRefiners(refiners || {}),
      orderBy: sorting,
      cacheStartingPage: cacheStartingPage,
      cacheEndingPage: cacheEndingPage,
      isLoadMore: projectChildLines?.length > 0,
    };

    return filter;
  };

  async function loadProjectChildLines(
    isResetPage: boolean = false,
    searchPhrase: string,
    refiners: any,
    sortField: string,
    sortOrder: number,
    cacheStartingPage: number,
    cacheEndingPage: number
  ) {
    const pageNo = getSetPageNo(isResetPage);
    if (pageNo == 1) resetScroll();
    const setLoading = pageNo == 1 ? setIsLoading : setIsLazyLoading;
    setLoading(true);

    const parameters = getQueryParams(
      (pageNo - 1) * itemsPerPage,
      itemsPerPage,
      searchPhrase,
      refiners,
      `${initialSortField.replace(".", "/")} ${initialSortOrder}`,
      cacheStartingPage,
      cacheEndingPage
    );

    if (getProjectLineListCancelSource.current) {
      getProjectLineListCancelSource.current.cancel("Cancelled");
    }

    getProjectLineListCancelSource.current = (
      axios as any
    ).CancelToken.source();

    const response = FlexIntegrationService.getProjectLines(
      parameters,
      getProjectLineListCancelSource.current?.token
    );

    response.then(async (result) => {
      if (result === undefined) return;
      const list = result.value || [];

      setIsBcpMode(result.isBcpMode);
      setCacheStartingPage(result.cacheStartingPage);
      setCacheEndingPage(result.cacheEndingPage);
      setIsLoadMore(result.totalRecords > 0 || list.length > 0);

      const filteredList = uniqBy(
        list,
        (item) => item.projectServiceLineId
      ).filter(
        (item) =>
          !e2eProjectLines?.some(
            (line) => line.flexProjectLineId === item.projectServiceLineId
          )
      );

      const mappedList = filteredList.map((l: FlexProjectServiceLineModel) => {
        const soldTo = l.project?.projectContacts?.find(
          (p: any) => p.contactRoleId === flexProjectContactRole.SoldTo
        );
        const shipTo = l.project?.projectContacts?.find(
          (p: any) => p.contactRoleId === flexProjectContactRole.ShipTo
        );

        return {
          ...l,
          promiseDate: formatDateWithTimezone(l.promiseDate),
          soldToPSN: soldTo?.partySiteNumber ?? shipTo?.partySiteNumber,
          soldToCompanyName:
            soldTo?.companyName ?? l.project.order?.companyName ?? "",
          shipToCompanyName:
            shipTo?.companyName ?? l.project.order?.companyName ?? "",
          shipToPSN: shipTo?.partySiteNumber ?? soldTo?.partySiteNumber,
          project: {
            ...l.project,
            // createdOn: formatDateWithTimezone(l.project.createdOn),
            // dateBooked: formatDateWithTimezone(l.project.dateBooked as Date),
            // order: {
            //   orderLineEndDate: formatDateWithTimezone(
            //     l.project.order?.orderLineEndDate
            //   ),
            // },
            //endDate: formatDateWithTimezone(l.project.endDate),
          },
          //projectLineEndDate: formatDateWithTimezone(l.projectLineEndDate),
        };
      });

      let newList =
        pageNo == 1 ? [] : JSON.parse(JSON.stringify(projectChildLines));

      newList.push(...mappedList);
      setRecordsShownCount(newList.length);

      if (newList.length) {
        await sortProjectChildLines(null, null, false, newList);
      } else {
        setProjectChildLines(newList);
      }

      setTotalRecords(result.totalRecords);

      if (projectIdsQueryParams?.length > 0) {
        setProjectIdsQueryParams([]);
        setSelectedProjectServiceLineIds(
          mappedList.map((projectLine: any) => projectLine.projectServiceLineId)
        );
      } else {
        setSelectedProjectServiceLineIds((prevList) => {
          let latestSelectedRow = newList.filter((x: any) =>
            prevList.includes(x.projectServiceLineId)
          );
          return latestSelectedRow.map(
            (projectLine: any) => projectLine.projectServiceLineId
          );
        });
      }

      const projectIds = mappedList.map(
        (projectLine: any) => projectLine.projectId
      );
      loadedList();
      await loadAssignFlexHandlerStatuses(projectIds, pageNo);
      setLoading(false);
    });
  }

  async function sortProjectChildLines(
    sortingField: any = null,
    sortingOrder: any = null,
    isTriggeredByHeader: boolean = false,
    projectList?: FlexProjectServiceLineModel[]
  ) {
    let sortColumn = initialSortField;
    let sortBy = !(initialSortOrder == "desc") ? 1 : -1;

    if (isTriggeredByHeader) {
      sortColumn =
        columns.find(
          (column) => column.id == (sortingField ?? initialSortField)
        )?.id || initialSortField;

      sortBy = sortingOrder ?? !(initialSortOrder == "desc");
    } else {
      sortColumn =
        columns.find((column) => column.id == (sortField ?? initialSortField))
          ?.id || initialSortField;

      sortBy = sortOrder ?? !(initialSortOrder == "desc");
    }

    if (projectList?.length) {
      if (sortColumn == "soldToPSN" || sortColumn == "shipToPSN") {
        projectList = _.orderBy(
          projectList,
          (o: any) => new Number(o[sortColumn]),
          [sortBy == 1 ? "asc" : "desc"]
        );
      } else {
        projectList = _.orderBy(
          projectList,
          [sortColumn],
          [sortBy == 1 ? "asc" : "desc"]
        );
      }
      setProjectChildLines(projectList);
    } else {
      if (sortColumn == "soldToPSN" || sortColumn == "shipToPSN") {
        setProjectChildLines((list) => {
          return _.orderBy(list, (o: any) => new Number(o[sortColumn]), [
            sortBy == 1 ? "asc" : "desc",
          ]);
        });
      } else {
        setProjectChildLines((list) => {
          return _.orderBy(list, [sortColumn], [sortBy == 1 ? "asc" : "desc"]);
        });
      }
    }
  }

  const loadAssignFlexHandlerStatuses = async (
    projectIds: string[],
    pageNo: number
  ) => {
    var { data } =
      await FlexIntegrationService.getProjectsLatestServiceBusEntries(
        projectIds
      );

    setAssignFlexHandlerStatuses((prevList) => {
      let newList = pageNo == 1 ? [] : JSON.parse(JSON.stringify(prevList));

      newList.push(...data);

      return newList;
    });
  };

  const handleFlexHandlerHeaderIconClick = () => {
    setShowAssignFlexHandlerModal(true);
  };

  const handleAssignFlexHandlerModalClose = () => {
    setShowAssignFlexHandlerModal(false);
  };

  const handleAssignFlexHandlerModalSave = (newHandlerEmail: string) => {
    setShowAssignFlexHandlerModal(false);
    handleAssignNewFlexHandler(selectedProjectIds, newHandlerEmail);
  };

  const flexHandlerHeaderTemplate = (headerText: string) => {
    return (
      <div className={classes["flex-handler-column__header"]}>
        <label>{headerText}</label>{" "}
        <button
          disabled={selectedRows.length === 0}
          onClick={handleFlexHandlerHeaderIconClick}
          style={{ all: "unset", cursor: "pointer" }}
        >
          <FontAwesomeIcon title="Assign FLEX Handler" icon={faEdit} />
        </button>
      </div>
    );
  };

  const renderProjectHandlerBody = (data: FlexProjectServiceLineModel) => (
    <AssignFlexHandlerCell
      projectHandlers={projectHandlers}
      projectServiceLine={data}
      status={getAssignProjectHandlerStatus(data.projectId)?.status}
      onAssignFlexHandler={(projectId, newHandlerEmail) =>
        handleAssignNewFlexHandler([projectId], newHandlerEmail)
      }
      inProgressAssignedFlexHandler={
        getAssignProjectHandlerStatus(data.projectId)?.newHandlerEmail
      }
      setColumns={setColumns}
    />
  );

  const getAssignProjectHandlerStatus = (
    projectId: string
  ): AssignFlexHandlerServiceBusEntry | undefined =>
    assignFlexHandlerStatuses.find((x) => x.projectId === projectId);

  const tableColumnHeader = (col: GridColumnModel) => {
    return (
      <div>
        <span title={col.value}>{col.value}</span>

        {col.isFrozen && (
          <ColumnResizer
            field={col.id}
            colWidth={col.width}
            onColumnResize={(e: any) => {
              onColResize(e);
            }}
            datatable={clv}
          />
        )}
      </div>
    );
  };

  const formatCellValue = (displayType: string, data: any) => {
    switch (displayType) {
      case "date":
        return formatDateWithTimezone(data);

      default:
        return data;
    }
  };

  const tableColumnBody = (
    displayType: string,
    rowData: FlexProjectServiceLineModel,
    col: ColumnBodyOptions
  ) => {
    return (
      <div
        title={formatCellValue(displayType, get(rowData, col.field))}
        className={"text-overflow-ellipsis"}
      >
        {formatCellValue(displayType, get(rowData, col.field))}
      </div>
    );
  };

  const renderDynamicColumns = () =>
    columns.map((col: GridColumnModelList) => (
      <Column
        columnKey={col.id}
        key={col.value}
        field={col.id}
        body={
          col.id === pWqColumnProjectHandler
            ? renderProjectHandlerBody
            : tableColumnBody.bind(col, col.displayType)
        }
        header={
          col.id === pWqColumnProjectHandler
            ? flexHandlerHeaderTemplate(col.value)
            : tableColumnHeader(col)
        }
        className={clsx(
          { [classes["last-frozen"]]: col.orderNo === pwqFrozenCount },
          classes["pwq-column"]
        )}
        frozen={col.isFrozen}
        alignFrozen="left"
        hidden={!col.isShown}
        style={{ width: col.width, flexBasis: col.width }}
        sortable={true}
      />
    ));

  const selectedRows = projectChildLines.filter((x) =>
    selectedProjectServiceLineIds.includes(x.projectServiceLineId)
  );

  const selectedLinesWithProjectCreationRestricted = selectedRows.some(
    (childLine: FlexProjectServiceLineModel) =>
      childLine.hasMultipleServiceLines || childLine.hasInvalidIndustryCode
  );

  const updateColumns = (
    frozenCount: number,
    fields: GridColumnModelList[]
  ) => {
    const _fields = fields.map((f: GridColumnModelList) => {
      if (f.orderNo <= frozenCount) {
        return {
          ...f,
          isShown: true,
          isFrozen: true,
        };
      } else {
        return {
          ...f,
          isFrozen: false,
        };
      }
    });

    setColumns(_fields);
    setpwqFrozenCount(frozenCount);
  };

  const hasSelectedRows = selectedRows.length > 0;

  const isCreateE2EButtonEnabled = hasSelectedRows;

  const handleSelectionChange = (e: any) => {
    setSelectedProjectServiceLineIds(
      e.value?.map(
        (x: FlexProjectServiceLineModel) => x.projectServiceLineId
      ) || []
    );
  };

  const handleCustomSearch = (searchText: string) => {
    let newSearchText = searchText;
    if (searchText || searchText != "") {
      newSearchText = newSearchText.trim();
    }

    setCustomSearchText(newSearchText);
    loadListDebounce(
      true,
      newSearchText,
      selectedRefiners,
      sortField,
      sortOrder
    );
  };

  const checkDiff = (a: string[]) => {
    return new Set(a).size !== 1;
  };

  const handleAssignNewFlexHandler = (
    projectIds: string[],
    newHandlerEmail: string
  ) => {
    setAssignFlexHandlerStatus(projectIds, "IN PROGRESS", newHandlerEmail);

    const model: AssignFlexHandlerModel = {
      newHandlerEmail,
      projects: projectChildLines
        .filter((x) => projectIds.includes(x.projectId))
        .map((x) => ({
          lineNumber: x.lineNumber,
          projectId: x.projectId,
          projectNumber: x.project.projectNumber,
        })),
    };

    FlexIntegrationService.assignFlexHandler(model).catch((e: AxiosError) => {
      showError("Error", e.response?.data);
      setAssignFlexHandlerStatus(projectIds, "FAILED", newHandlerEmail);
    });
  };

  const setAssignFlexHandlerStatus = (
    projectIds: string[],
    status: AssignFlexHandlerStatus,
    newHandlerEmail?: string
  ) => {
    setAssignFlexHandlerStatuses((curr) => {
      const retainStatuses = curr.filter(
        (f) => !projectIds.includes(f.projectId)
      );

      const newStatuses = projectIds.map(
        (p) =>
          ({
            projectId: p,
            status,
            newHandlerEmail,
          } as AssignFlexHandlerServiceBusEntry)
      );

      return [...retainStatuses, ...newStatuses];
    });
  };

  const hasSelectedRowsWithDifferentApplicants =
    selectedRows.length > 1 &&
    checkDiff(selectedRows.map((s: any) => s.soldToPSN));

  const selectedProjectIds = selectedRows.map((x) => x.projectId);

  const handleSelectedRefinersChange = (refiners: any) => {
    setSelectedRefiners(refiners);
    loadListDebounce(true, customSearchText, refiners, sortField, sortOrder);
  };

  const isDataArrayNotNullWithData = (data: any): boolean => {
    return data && Array.isArray(data) && data.length > 0;
  };

  const getDeliveryPathListByCatalog = async (
    projectLines: E2EProjectLineModel[]
  ): Promise<E2EProjectLineModel[]> => {
    const isWireless = selectedRows.find((f) =>
      f.ServiceCategoryDescription?.toLocaleLowerCase().includes("wireless")
    );
    const servicePrograms = isWireless
      ? selectedRows.map((m) => m.serviceProgram)
      : selectedRows.map((m) => m.serviceCatalogItemNo);
    const serviceProgramsCsv = getCsvFormatWithoutQuotes(servicePrograms);

    await ReferenceService.getDeliveryPathByCatalog(serviceProgramsCsv)
      .then((response) => {
        if (response) {
          if (response.data) {
            const catalogs =
              response.data as GetDeliveryPathByCatalogResultModel[];

            if (isDataArrayNotNullWithData(response.data)) {
              projectLines.forEach((rowData) => {
                const catalog = catalogs.find(
                  (f) => f.itemNumber == rowData.serviceCatalogItemNo
                );

                if (catalog) {
                  if ((catalog.certificateSchemeList?.length ?? 0) > 0) {
                    rowData.isShowAllScheme = false;
                    rowData.isShowAllSchemeDisabled = false;

                    rowData.initialCertificateSchemeList =
                      catalog.certificateSchemeList;
                    rowData.certificateSchemeList =
                      catalog.certificateSchemeList;
                    rowData.schemeId = catalog.certificateSchemeList?.find(
                      (f) => f.selected
                    )?.id;
                    rowData.schemeName = catalog.certificateSchemeList?.find(
                      (f) => f.selected
                    )?.value;
                  }

                  rowData.applicationTypeList = catalog.applicationTypeList;
                  rowData.applicationTypeId = catalog.applicationTypeList?.find(
                    (f) => f.selected
                  )?.id;
                  rowData.deliveryPathList = catalog.deliveryPathList;
                  rowData.deliveryPathId = catalog.deliveryPathList?.find(
                    (f) => f.selected
                  )?.id;
                }
              });
            } else {
            }
          } else {
            //TODO: show error message
          }
        } else {
          //TODO: show error message
        }

        return projectLines;
      })
      .catch((error) => {
        console.error(error);
      });

    return projectLines;
  };

  const getProjectListByCustomer = async (
    accountNo?: string | null,
    partySiteNumber?: string | null
  ) => {
    const fetchedExistingProjects =
      await ProjectService.getProjectListByCustomer(accountNo, partySiteNumber);

    dispatch(updateE2EProjectList(fetchedExistingProjects.data));
    setSelectedAccountNo(accountNo?.toString() ?? "");
    setPartySiteNumber(partySiteNumber?.toString() ?? "");

    return fetchedExistingProjects.data;
  };

  const handleCreateE2EProject = async () => {
    setIsLoading(true);

    let lines =
      selectedRows.map((x: FlexProjectServiceLineModel) => {
        const soldToCustomer = x?.project.projectContacts.find(
          (a) => a.contactRoleId === flexProjectContactRole.SoldTo
        );
        const billToCustomer = x?.project.projectContacts.find(
          (a) => a.contactRoleId === flexProjectContactRole.BillTo
        );
        const shipToCustomer = x?.project.projectContacts.find(
          (a) => a.contactRoleId === flexProjectContactRole.ShipTo
        );

        return {
          id: null,
          projectCreatedOn: x?.project.createdOn,
          e2EProjectId: null,
          e2EProjectTemplateId: null,
          externalId: x.externalId,
          parentExternalId: x?.parentExternalId,
          flexProjectId: x?.projectId,
          flexProjectLineId: x?.projectServiceLineId,
          flexParentLineId: x?.parentLineId,
          flexProjectName: x?.project.name ?? "",
          flexProjectNumber: x?.project.projectNumber ?? "",
          version: null,
          orderNumber: x?.project.orderNumber ?? "",
          quoteNumber: x?.project.quoteNo,
          parentLineNumber: x?.parentLineNumber,
          lineNumber: x?.lineNumber,
          itemNumber: x?.serviceCatalogItemNo,
          orderLineStatus:
            x?.projectLineStatus == "OnHold"
              ? ProjectOrderlineStatus.OnHold
              : ProjectOrderlineStatus.InProgress,
          fulFillmentStatusDetailId: x?.fulFillmentStatusDetailId,
          industryCode: x?.industryCode,
          serviceCategory: x?.ServiceCategoryDescription,
          serviceLineCode: x?.serviceCode,
          serviceSubCategory: x?.serviceSubCategoryDescription,
          serviceProgram: x?.serviceProgramDesc,
          serviceSegment: x?.serviceSegmentDesc,
          serviceDetail: x?.clientDetailService,
          serviceCatalogItemNo: x?.serviceCatalogItemNo,
          serviceCatalogDescription: x?.serviceCatalogDescription || x.name,
          companyExternalId: x?.project.companyExternalId,
          companyName: x?.project.companyName,
          locationCode: x?.locationCode,
          applicantPartySiteNumber:
            soldToCustomer?.partySiteNumber ?? shipToCustomer?.partySiteNumber,
          applicantName:
            soldToCustomer?.companyName ?? shipToCustomer?.companyName,
          productOwnerName: shipToCustomer?.companyName,
          productOwnerPartySiteNumber: shipToCustomer?.partySiteNumber,
          isShowAllScheme: true,
          isShowAllSchemeDisabled: true,
          schemeId: null,
          schemeName: null,
          deliveryPathId: null,
          applicationTypeId: null,
          additionalInfoId: null,
          leadTypeId: 1,
          leadTime: 0,
          orderBookedDate: x?.project?.dateBooked ?? x?.project?.dateOrdered,
          estimatedCompletionDate: x?.projectLineEndDate,
          startDate: x?.project.startDate ?? "",
          endDate: x?.project.endDate ?? "",
          flexProjectHandler: x?.project.projectHandler,
          isFirstChild: false,
          isFlexWritebackFailed: false,
          soldToCustomer: {
            name:
              soldToCustomer?.companyName ??
              shipToCustomer?.companyName ??
              x.project?.order?.companyName,
            normalizedName: (
              soldToCustomer?.companyName ??
              shipToCustomer?.companyName ??
              x.project?.order?.companyName
            )?.toLocaleLowerCase(),
            accountNumber:
              soldToCustomer?.externalId ?? shipToCustomer?.externalId,
            status: 1,
          },
          soldToCustomerLocation: {
            partySiteNumber:
              soldToCustomer?.partySiteNumber ??
              shipToCustomer?.partySiteNumber ??
              "",
            address1: soldToCustomer?.address ?? shipToCustomer?.address,
            city: soldToCustomer?.city ?? shipToCustomer?.city,
            state: soldToCustomer?.state ?? shipToCustomer?.state,
            province: soldToCustomer?.province ?? shipToCustomer?.province,
            country: soldToCustomer?.country ?? shipToCustomer?.country ?? "",
            region: "",
            postalCode:
              soldToCustomer?.postalCode ?? shipToCustomer?.postalCode,
            type: soldToCustomer?.contactRoleId,
          },
          soldToCustomerContact: {
            emailAddress: soldToCustomer?.email ?? shipToCustomer?.email,
            firstName:
              soldToCustomer?.companyName ??
              soldToCustomer?.fullName ??
              shipToCustomer?.companyName ??
              shipToCustomer?.fullName,
            phoneNumber: soldToCustomer?.phone ?? soldToCustomer?.phone,
            type: soldToCustomer?.contactRoleId,
          },
          billToCustomer: {
            name: billToCustomer?.companyName ?? x?.project?.companyName,
            normalizedName: (
              billToCustomer?.companyName ?? x?.project?.companyName
            )?.toLocaleLowerCase(),
            accountNumber: billToCustomer?.externalId,
            status: 1,
          },
          billToCustomerLocation: {
            partySiteNumber: billToCustomer?.partySiteNumber ?? "",
            address1: billToCustomer?.address,
            city: billToCustomer?.city,
            state: billToCustomer?.state,
            province: billToCustomer?.province,
            country: billToCustomer?.country ?? "",
            region: "",
            postalCode: billToCustomer?.postalCode,
            type: billToCustomer?.contactRoleId,
          },
          billToCustomerContact: {
            emailAddress: billToCustomer?.email,
            firstName: billToCustomer?.companyName ?? billToCustomer?.fullName,
            phoneNumber: billToCustomer?.phone,
            type: billToCustomer?.contactRoleId,
          },
          shipToCustomer: {
            name: shipToCustomer?.companyName ?? x?.project?.companyName,
            normalizedName: (
              shipToCustomer?.companyName ?? x?.project?.companyName
            )?.toLocaleLowerCase(),
            accountNumber: shipToCustomer?.externalId,
            status: 1,
          },
          shipToCustomerLocation: {
            partySiteNumber: shipToCustomer?.partySiteNumber ?? "",
            address1: shipToCustomer?.address,
            city: shipToCustomer?.city,
            state: shipToCustomer?.state,
            province: shipToCustomer?.province,
            country: shipToCustomer?.country ?? "",
            region: "",
            postalCode: shipToCustomer?.postalCode,
            type: shipToCustomer?.contactRoleId,
          },
          shipToCustomerContact: {
            emailAddress: shipToCustomer?.email,
            firstName: shipToCustomer?.companyName ?? billToCustomer?.fullName,
            phoneNumber: shipToCustomer?.phone,
            type: shipToCustomer?.contactRoleId,
          },
          orderLineNumber: x?.lineNumber ?? "",
          orderLineDescription: x?.lineNumberWithDescription ?? "",
          technologies: [] as any,
          initialCertificateSchemeList: [] as any,
          flexProjectTemplateId:
            x?.project?.projectProjectTemplates[0].projectTemplateId,
          certificateSchemeList: certificateSchemeList,
          flexProjectCreatorUserId: x?.project?.createdBy,
          orderOwnerEmail: x?.project?.orderOwner,
          externalProjectId: x?.project?.externalProjectId,
          applicationTypeList: [] as any,
          additionalInfoList: [] as any,
          deliveryPathList: [] as any,
          e2eTemplateList: [] as any,
          isSelectable: true,
          isCpq: x?.isCpq,
          e2eFlexProject: getFlexProject(x),
          price: x?.price,
          currency: x?.currency,
          name: x?.name,
        } as E2EProjectLineModel;
      }) || [];

    if (lines.length > 1) {
      const baseLines = [
        {
          flexProjectLineId: "0",
          segment: "Wireless",
          certificateSchemeList: certificateSchemeList,
          isSelectable: false,
        } as E2EProjectLineModel,
      ];

      lines = [...baseLines, ...lines];
    }

    const firstRow = lines.find((f) => f.flexProjectLineId !== "0");

    const companyName = selectedRows[0].project.order?.companyName ?? "";
    let soldToName = firstRow?.soldToCustomer?.name ?? "";
    let shipToName = firstRow?.shipToCustomer?.name ?? "";
    const accountNo = firstRow?.soldToCustomer?.accountNumber ?? "";
    const customerLocation = firstRow?.soldToCustomerLocation;
    const customerContact = firstRow?.soldToCustomerContact;
    const soldToPsn =
      firstRow?.soldToCustomerLocation?.partySiteNumber ||
      firstRow?.shipToCustomerLocation?.partySiteNumber ||
      firstRow?.applicantPartySiteNumber;
    const shipToPsn =
      firstRow?.shipToCustomerLocation?.partySiteNumber ||
      firstRow?.applicantPartySiteNumber;

    if (soldToName.length === 0) {
      soldToName = companyName;
    }

    if (shipToName.length === 0) {
      shipToName = companyName;
    }

    const customer = {
      name: soldToName,
      normalizedName: soldToName.toLocaleLowerCase(),
      accountNumber: accountNo,
      partySiteNumber: soldToPsn,
      status: 1,
    } as CustomerModel;

    const applicant = {
      companyName: soldToName,
      partySiteNumber: soldToPsn,
    };

    const productOwner = {
      companyName: shipToName,
      partySiteNumber: shipToPsn,
    };

    let projectLines = await getDeliveryPathListByCatalog(lines);

    projectLines = await getFlexProjectTemplate(projectLines);

    const existingProjects = await getProjectListByCustomer(
      accountNo,
      soldToPsn
    );

    dispatch(updateCustomer(customer));
    dispatch(updateCustomerLocation(customerLocation));
    dispatch(updateCustomerContact(customerContact));
    dispatch(updateApplicant(applicant));
    dispatch(updateProductOwner(productOwner));
    dispatch(updateInitialE2EProjectLines(projectLines));
    dispatch(updateProjectLines(projectLines));
    setInitialRefiners(selectedRefiners);
    setInitialGlobalSearch(customSearchText);
    setExistingProjectLine(
      existingProjectLine.concat(selectedRows.map((x) => x.externalId))
    );
    setIsLoading(false);

    if (flexUnsupportedTemplateList.length) {
      setShowFlexUnsupportedTemplateListModal(true);

      let unsupportedFlexTemplateDataProps: UnsupportedFlexTemplateDataProps = {
        type: "isCreateE2EProject",
        isProceedBtnDisabled:
          flexUnsupportedTemplateList.length == selectedRows.length,
        companyName: applicant.companyName,
        hasExistingProjects: existingProjects?.length > 0,
      };

      setUnsupportedTemplateDataProp(unsupportedFlexTemplateDataProps);
      return;
    }

    const hasExistingProjects = existingProjects?.length > 0;

    if (hasExistingProjects) {
      setIsProjectAlreadyExistsModalVisible(true);
    } else {
      dispatch(updateE2EProjectId(null));

      dispatch(
        updateE2EProjectName(
          ProjectService.constructProjectName(applicant.companyName)
        )
      );

      setCreateProjectModalVisible(true);
    }
  };

  const getFlexProjectTemplate = async (
    projectLines: E2EProjectLineModel[]
  ): Promise<E2EProjectLineModel[]> => {
    let projectIds: string[] = projectLines
      .map((m) => m.flexProjectId ?? "")
      .filter((id) => id !== null && id !== undefined && id !== "");

    setFlexUnsupportedTemplateList([]);
    setUnsupportedTemplateDataProp(null);

    await FlexIntegrationService.getFlexTemplateByFlexProjectIds(projectIds)
      .then((response) => {
        if (response.isSuccess) {
          const flexProjectTemplates = response.data;

          projectLines.forEach((rowData) => {
            if (rowData.flexProjectId) {
              const flexProjectTemplate = flexProjectTemplates.find(
                (f) => f.flexProjectId === rowData.flexProjectId
              );

              if (flexProjectTemplate?.isSupported) {
                rowData.flexProjectTemplateName =
                  flexProjectTemplate?.projectTemplateName;
              } else {
                let unsupportedTemplate: FlexUnsupportedTemplateListModel = {
                  externalId: rowData.externalId,
                  projectNumber: rowData.flexProjectNumber,
                  orderLineNumber:
                    rowData.orderLineDescription?.toString() ?? "",
                  flexTemplate: flexProjectTemplate?.projectTemplateName,
                };

                flexUnsupportedTemplateList.push(unsupportedTemplate);
                setFlexUnsupportedTemplateList(flexUnsupportedTemplateList);
              }
            }
          });

          return projectLines;
        }
      })
      .catch((error) => console.log(error));

    return projectLines;
  };

  const handleOnConfirmUnsupportedOrderLines = async (
    resp: UnsupportedFlexTemplateDataProps | null
  ) => {
    setIsLoading(true);

    let unsupportedProjectLines = flexUnsupportedTemplateList.map(
      (m) => m.externalId
    );

    let projectLines = e2eProjectLines?.filter(
      (f) => !unsupportedProjectLines.includes(f.externalId)
    );

    dispatch(updateProjectLines(projectLines));

    if (resp?.type == "isCreateE2EProject") {
      setIsLoading(false);

      if (resp.hasExistingProjects) {
        setIsProjectAlreadyExistsModalVisible(true);
      } else {
        dispatch(updateE2EProjectId(null));

        dispatch(
          updateE2EProjectName(
            ProjectService.constructProjectName(resp?.companyName ?? "")
          )
        );

        setCreateProjectModalVisible(true);
      }
    } else {
      setIsAddOrderLine(false);
      setCreateProjectModalVisible(true);
      setIsLoading(false);
    }

    setShowFlexUnsupportedTemplateListModal(false);
  };

  const handleCloseCreateE2EProjectModal = () => {
    dispatch(resetInitialProjectLines());
    dispatch(resetProjectLines());
    setSelectedRefiners(initialRefiners);
    setCustomSearchText(initialGlobalSearch);
    setDisabledRefiners({});
    setCreateProjectModalVisible(false);
    setFlexUnsupportedTemplateList([]);
    setUnsupportedTemplateDataProp(null);
  };

  const handleProjectAlreadyExistsModalProceed = (
    projectId: string,
    projectName: string
  ) => {
    dispatch(
      updateE2EProjectName(
        projectId === "0"
          ? ProjectService.constructProjectName(e2eApplicant.companyName)
          : projectName
      )
    );

    dispatch(updateE2EProjectId(projectId));

    setIsProjectAlreadyExistsModalVisible(false);
    setCreateProjectModalVisible(true);
  };

  const handleProjectAlreadyExistsModalCancel = () => {
    setIsProjectAlreadyExistsModalVisible(false);
  };

  const handleAddOrderLine = async (lines: E2EProjectLineModel[]) => {
    dispatch(updateProjectLines(lines));
    setIsAddOrderLine(true);
    setCreateProjectModalVisible(false);
  };

  const handleCancelAddOrderLine = () => {
    setIsAddOrderLine(false);
    setCreateProjectModalVisible(true);
  };

  const handleAddToGMAWBProject = async () => {
    setIsLoading(true);

    let currentProjectLines = e2eProjectLines;

    let lines =
      selectedRows.map((x: FlexProjectServiceLineModel) => {
        const soldToCustomer = x?.project.projectContacts.find(
          (a) => a.contactRoleId === flexProjectContactRole.SoldTo
        );
        const billToCustomer = x?.project.projectContacts.find(
          (a) => a.contactRoleId === flexProjectContactRole.BillTo
        );
        const shipToCustomer = x?.project.projectContacts.find(
          (a) => a.contactRoleId === flexProjectContactRole.ShipTo
        );

        return {
          id: null,
          projectCreatedOn: x?.project.createdOn,
          e2EProjectId: null,
          e2EProjectTemplateId: null,
          externalId: x.externalId,
          parentExternalId: x.parentExternalId,
          flexProjectId: x?.projectId,
          flexProjectLineId: x?.projectServiceLineId,
          flexParentLineId: x?.parentLineId,
          flexProjectName: x?.project.name ?? "",
          flexProjectNumber: x?.project.projectNumber ?? "",
          version: null,
          orderNumber: x?.project.orderNumber ?? "",
          quoteNumber: x?.project.quoteNo,
          parentLineNumber: x?.parentLineNumber,
          lineNumber: x?.lineNumber,
          itemNumber: x?.serviceCatalogItemNo,
          orderLineStatus:
            x?.projectLineStatus == "OnHold"
              ? ProjectOrderlineStatus.OnHold
              : ProjectOrderlineStatus.InProgress,
          fulFillmentStatusDetailId: x?.fulFillmentStatusDetailId,
          industryCode: x?.industryCode,
          serviceCategory: x?.ServiceCategoryDescription,
          serviceLineCode: x?.serviceCode,
          serviceSubCategory: x?.serviceSubCategoryDescription,
          serviceProgram: x?.serviceProgramDesc,
          serviceSegment: x?.serviceSegmentDesc,
          serviceDetail: x?.clientDetailService,
          serviceCatalogItemNo: x?.serviceCatalogItemNo,
          serviceCatalogDescription: x?.serviceCatalogDescription || x.name,
          companyExternalId: x?.project.companyExternalId,
          companyName: x?.project.companyName,
          locationCode: x?.locationCode,
          applicantPartySiteNumber:
            soldToCustomer?.partySiteNumber ?? shipToCustomer?.partySiteNumber,
          applicantName:
            soldToCustomer?.companyName ?? shipToCustomer?.companyName,
          productOwnerName: shipToCustomer?.companyName,
          productOwnerPartySiteNumber: shipToCustomer?.partySiteNumber,
          isShowAllScheme: true,
          isShowAllSchemeDisabled: true,
          schemeId: null,
          schemeName: null,
          deliveryPathId: null,
          applicationTypeId: null,
          additionalInfoId: null,
          leadTypeId: 1,
          leadTime: 0,
          orderBookedDate: x?.project.dateBooked ?? x?.project.dateOrdered,
          estimatedCompletionDate: x?.projectLineEndDate,
          startDate: x?.project.startDate ?? "",
          endDate: x?.project.endDate ?? "",
          flexProjectHandler: x?.project.projectHandler,
          isFirstChild: false,
          isFlexWritebackFailed: false,
          soldToCustomer: {
            name: soldToCustomer?.companyName ?? x?.project?.companyName,
            normalizedName: (
              soldToCustomer?.companyName ?? x?.project?.companyName
            )?.toLocaleLowerCase(),
            accountNumber: soldToCustomer?.externalId,
            status: 1,
          },
          soldToCustomerLocation: {
            partySiteNumber: soldToCustomer?.partySiteNumber ?? "",
            address1: soldToCustomer?.address,
            city: soldToCustomer?.city,
            state: soldToCustomer?.state,
            province: soldToCustomer?.province,
            country: soldToCustomer?.country ?? "",
            region: "",
            postalCode: soldToCustomer?.postalCode,
            type: soldToCustomer?.contactRoleId,
          },
          soldToCustomerContact: {
            emailAddress: soldToCustomer?.email,
            firstName: soldToCustomer?.companyName ?? billToCustomer?.fullName,
            phoneNumber: soldToCustomer?.phone,
            type: soldToCustomer?.contactRoleId,
          },
          billToCustomer: {
            name: billToCustomer?.companyName ?? x?.project?.companyName,
            normalizedName: (
              billToCustomer?.companyName ?? x?.project?.companyName
            )?.toLocaleLowerCase(),
            accountNumber: billToCustomer?.externalId,
            status: 1,
          },
          billToCustomerLocation: {
            partySiteNumber: billToCustomer?.partySiteNumber ?? "",
            address1: billToCustomer?.address,
            city: billToCustomer?.city,
            state: billToCustomer?.state,
            province: billToCustomer?.province,
            country: billToCustomer?.country ?? "",
            region: "",
            postalCode: billToCustomer?.postalCode,
            type: billToCustomer?.contactRoleId,
          },
          billToCustomerContact: {
            emailAddress: billToCustomer?.email,
            firstName: billToCustomer?.companyName ?? billToCustomer?.fullName,
            phoneNumber: billToCustomer?.phone,
            type: billToCustomer?.contactRoleId,
          },
          shipToCustomer: {
            name: shipToCustomer?.companyName ?? x?.project?.companyName,
            normalizedName: (
              shipToCustomer?.companyName ?? x?.project?.companyName
            )?.toLocaleLowerCase(),
            accountNumber: shipToCustomer?.externalId,
            status: 1,
          },
          shipToCustomerLocation: {
            partySiteNumber: shipToCustomer?.partySiteNumber ?? "",
            address1: shipToCustomer?.address,
            city: shipToCustomer?.city,
            state: shipToCustomer?.state,
            province: shipToCustomer?.province,
            country: shipToCustomer?.country ?? "",
            region: "",
            postalCode: shipToCustomer?.postalCode,
            type: shipToCustomer?.contactRoleId,
          },
          shipToCustomerContact: {
            emailAddress: shipToCustomer?.email,
            firstName: shipToCustomer?.companyName ?? billToCustomer?.fullName,
            phoneNumber: shipToCustomer?.phone,
            type: shipToCustomer?.contactRoleId,
          },
          orderLineNumber: x?.lineNumber ?? "",
          orderLineDescription: x?.lineNumberWithDescription ?? "",
          technologies: [] as any,
          initialCertificateSchemeList: [] as any,
          flexProjectTemplateId:
            x?.project?.projectProjectTemplates[0].projectTemplateId,
          certificateSchemeList: certificateSchemeList,
          flexProjectCreatorUserId: x?.project?.createdBy,
          orderOwnerEmail: x?.project?.orderOwner,
          externalProjectId: x?.project?.externalProjectId,
          applicationTypeList: [] as any,
          additionalInfoList: [] as any,
          deliveryPathList: [] as any,
          e2eTemplateList: [] as any,
          isSelectable: true,
          isRecentlyAdded: true,
          e2eFlexProject: getFlexProject(x),
          price: x?.price,
          currency: x?.currency,
          name: x?.name,
        } as E2EProjectLineModel;
      }) || [];

    if (!!currentProjectLines && currentProjectLines.length == 1) {
      const baseLines = [
        {
          flexProjectLineId: "0",
          segment: "Wireless",
          certificateSchemeList: certificateSchemeList,
          isSelectable: false,
        } as E2EProjectLineModel,
      ];

      currentProjectLines = [...baseLines, ...currentProjectLines];
    }

    let projectLines = await getDeliveryPathListByCatalog(lines);

    projectLines = await getFlexProjectTemplate(projectLines);

    currentProjectLines = currentProjectLines?.map((line) => ({
      ...line,
      isRecentlyAdded: false,
    }));

    dispatch(updateProjectLines(currentProjectLines?.concat(projectLines)));
    setExistingProjectLine(
      existingProjectLine.concat(selectedRows.map((x) => x.externalId))
    );

    if (flexUnsupportedTemplateList.length) {
      setShowFlexUnsupportedTemplateListModal(true);

      let unsupportedFlexTemplateDataProps: UnsupportedFlexTemplateDataProps = {
        type: "isAddToGMAWBProject",
        isProceedBtnDisabled:
          flexUnsupportedTemplateList.length == selectedRows.length,
      };

      setUnsupportedTemplateDataProp(unsupportedFlexTemplateDataProps);
      return;
    }

    setIsAddOrderLine(false);
    setCreateProjectModalVisible(true);
    setIsLoading(false);
  };

  const handleLoadMore = () => {
    loadProjectChildLines(
      false,
      customSearchText,
      selectedRefiners,
      sortField,
      sortOrder,
      cacheStartingPage,
      cacheEndingPage
    );
  };

  const onColReorder = (e: any) => {
    if (
      e.dropIndex === 0 ||
      e.dropIndex <= pwqFrozenCount ||
      e.dragIndex <= pwqFrozenCount ||
      e.dropIndex === columns.length + 1
    ) {
      clv.current?.reset();
      return;
    }

    const pwqColumns = columns.map((col, i) => {
      const orderNo =
        e.columns.findIndex((fi: any) => fi.props.field === col.id) - 1;

      return {
        ...col,
        orderNo,
      };
    });

    pwqColumns.sort(function (a, b) {
      return a.orderNo - b.orderNo;
    });

    updateColumns(pwqFrozenCount, pwqColumns);
  };

  const onColResize = (e: any) => {
    const newColumns = columns.map((col, i) => {
      if (e.column.props.field !== col.id || col.isFrozen) return col;

      return {
        ...col,
        width: e.element.offsetWidth,
      };
    });
    updateColumns(pwqFrozenCount, newColumns);
  };

  const handleFavoriteSelect = (props: any) => {
    setSelectedRefiners(props["refiner"]);
    setColumns(props["columns"]);
    setCustomSearchText(props["customSearchText"]);
    setpwqFrozenCount(props["frozenCount"]);
    const sortField = props["sort"]?.sortField || defaultSortField;
    const sortOrder = props["sort"]?.sortOrder || defaultSortOrder;
    setSortField(sortField);
    setSortOrder(sortOrder);
    loadListDebounce(
      true,
      props["customSearchText"],
      props["refiner"],
      sortField,
      sortOrder
    );
  };

  const handleOnSetToDefaultFilter = () => {
    setSelectedRefiners(defaultRefiners);
    setColumns(projectWorkQueueGridColumns);
    setCustomSearchText("");
    setpwqFrozenCount(8);
    loadListDebounce(
      true,
      "",
      defaultRefiners,
      defaultSortField,
      defaultSortOrder
    );
  };

  const handleFavoriteInit = (props: any) => {
    if (props) {
      !hasCustomQueryRefiner && setSelectedRefiners(props["refiner"]);
      setColumns(props["columns"]);
      setCustomSearchText(props["customSearchText"]);
      setpwqFrozenCount(props["frozenCount"]);
      const sortField = props["sort"]?.sortField || defaultSortField;
      const sortOrder = props["sort"]?.sortOrder || defaultSortOrder;
      setSortField(sortField);
      setSortOrder(sortOrder);
    }

    setIsFavoriteRefinersLoaded(true);
  };

  const onProjectCreationWarningIconClick = (
    data: FlexProjectServiceLineModel
  ) => {
    setProjectCreationRestrictions({
      hasInvalidIndustryCode: data.hasInvalidIndustryCode,
      hasMultipleServiceLines: data.hasMultipleServiceLines,
    });

    setShowRestrictCreationMessageModal(true);
  };

  const onProjectCreationWarningIconClose = () => {
    setProjectCreationRestrictions({});
    setShowRestrictCreationMessageModal(false);
  };

  const datatableFooter = () => (
    <>
      {
        <div>
          {showLoadMore() && !isLoading && (
            <>
              <span
                className={`${classes["load-more"]} ${
                  isLazyLoading && classes["disabled"]
                }`}
                onClick={() => !isLazyLoading && handleLoadMore()}
              >
                Load More
              </span>
              {isLazyLoading && (
                <span>
                  <FontAwesomeIcon icon={faSpinner} spin={true} />
                </span>
              )}
            </>
          )}
          {!showLoadMore() && recordsShownCount != 0 && (
            <span className={classes["end-of-list"]}>
              - All records has been loaded -
            </span>
          )}
        </div>
      }
    </>
  );

  const getFlexProject = (line: FlexProjectServiceLineModel) => {
    const flexProject: E2EFlexProjectModel = {
      flexProjectId: line?.projectId,
      flexProjectName: line?.project?.name,
      flexProjectNumber: line?.project?.projectNumber,
      orderNumber: line?.project?.orderNumber,
      companyExternalId: line?.project?.companyExternalId,
      externalProjectId: line?.project?.externalProjectId,
      flexProjectCreatorUserId: line?.project?.createdBy,
      description: line?.project?.description,
      status:
        line?.project?.projectStatus === "OnHold"
          ? ProjectStatus.OnHold
          : ProjectStatus.InProgress,
      projectStatusReasonId: line?.project?.projectStatusDetailId,
    };

    return flexProject;
  };

  return (
    <>
      <div className={classes["container"]}>
        <ProjectWorkQueueRefiners
          customSearchText={customSearchText}
          onCustomSearchTextChange={setCustomSearchText}
          onCustomSearch={handleCustomSearch}
          selectedRefiners={selectedRefiners}
          onSelectedRefinersChange={handleSelectedRefinersChange}
          disabledRefiners={disabledRefiners}
          addOrderLineDefaultRefiners={addOrderLineDefaultRefiners}
        />
        <div className={classes["right"]}>
          <div className={classes["table-container"]}>
            <div className={classes["table__header"]}>
              <div className={classes["header-record__container"]}>
                <div className={classes["header-record-shown"]}>
                  ({recordsShownCount || 0}) Records Shown
                </div>
                <span style={{ marginLeft: "0.25rem" }}>
                  | Click
                  <FontAwesomeIcon
                    icon={faWarning}
                    style={{
                      color: "#f7a80f",
                      marginLeft: "0.25rem",
                      marginRight: "0.25rem",
                    }}
                  ></FontAwesomeIcon>
                  for details.
                </span>
              </div>
              <div className={classes["header-button-container"]}>
                <div
                  title={favoriteTooltip ? favoriteTooltip : "Favorites"}
                  className={classes["table__header__actions"]}
                >
                  <Favorites
                    favoriteTypesId={favoritTypeId}
                    onFavoriteSelect={handleFavoriteSelect}
                    jsonModel={selectFavoritesModel}
                    onFavoriteInit={handleFavoriteInit}
                    onSetToDefaultFilter={handleOnSetToDefaultFilter}
                    setFavoriteToolTip={setFavoriteToolTip}
                    isDisabled={isAddOrderLine}
                  />

                  <ShowHideColumnsPrimeDataTable
                    fields={columns}
                    frozenColumnCount={pwqFrozenCount}
                    updateColumns={updateColumns}
                    setColumns={setColumns}
                    maxFrozenColumnCount={8}
                    hasShowAndFreezeSelection={true}
                  />
                </div>
              </div>
            </div>

            <div className="pwq-table-container">
              <DataTable
                key={databaseKey}
                ref={clv}
                className={`${classes["table"]} ${
                  projectChildLines.length === 0 ? classes["no-data"] : ""
                }`}
                reorderableColumns
                scrollable
                scrollDirection="both"
                value={projectChildLines}
                columnResizeMode="expand"
                responsiveLayout="scroll"
                loading={isLoading}
                selection={selectedRows}
                onSelectionChange={handleSelectionChange}
                selectionMode="checkbox"
                showGridlines
                onColReorder={onColReorder}
                resizableColumns
                onColumnResizeEnd={onColResize}
                onSort={(event: DataTablePFSEvent) => handleSort(event)}
                sortField={sortField}
                sortOrder={sortOrder}
                footer={
                  recordsShownCount || recordsShownCount > 0
                    ? datatableFooter
                    : null
                }
                lazy
                emptyMessage={"--- No records found. ---"}
                removableSort
                size="small"
              >
                <Column
                  reorderable={false}
                  selectionMode="multiple"
                  alignFrozen="left"
                  frozen
                  bodyStyle={{ borderRight: "none", flex: "none" }}
                  headerStyle={{ borderRight: "none", flex: "none" }}
                  style={{ maxWidth: "40px", flex: "none" }}
                ></Column>
                <Column
                  reorderable={false}
                  field=""
                  header=""
                  alignFrozen="left"
                  frozen
                  body={(data: FlexProjectServiceLineModel) => {
                    if (
                      data?.hasMultipleServiceLines ||
                      data?.hasInvalidIndustryCode
                    ) {
                      return (
                        <FontAwesomeIcon
                          icon={faWarning}
                          style={{
                            color: "#f7a80f",
                            marginLeft: "0.25rem",
                            marginRight: "0.25rem",
                            paddingTop: "0.25rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            onProjectCreationWarningIconClick(data);
                          }}
                        ></FontAwesomeIcon>
                      );
                    }

                    return <></>;
                  }}
                  style={{
                    maxWidth: "40px",
                    width: "40px",
                    borderLeft: "none",
                    flex: "none",
                  }}
                  headerStyle={{ borderLeft: "none", flex: "none" }}
                  bodyStyle={{ flex: "none" }}
                ></Column>
                {renderDynamicColumns()}
                <Column
                  columnKey="ellipsis-column"
                  frozen
                  alignFrozen="right"
                  body={(rowData) => <ActionsColumnBody rowData={rowData} />}
                />
              </DataTable>
            </div>
          </div>
          <div className={classes["right__footer"]}>
            {!isAddOrderLine ? (
              <Button
                disabled={
                  !isCreateE2EButtonEnabled ||
                  hasSelectedRowsWithDifferentApplicants ||
                  isLoading ||
                  selectedLinesWithProjectCreationRestricted
                }
                className={classes["btn-create-e2e"]}
                onClick={handleCreateE2EProject}
              >
                CREATE GMAWB PROJECT(S)
              </Button>
            ) : (
              <>
                <Button
                  className={classes["btn-cancel"]}
                  onClick={handleCancelAddOrderLine}
                >
                  CANCEL
                </Button>
                <Button
                  className={classes["btn-add-order-line"]}
                  disabled={
                    !isCreateE2EButtonEnabled ||
                    hasSelectedRowsWithDifferentApplicants ||
                    isLoading ||
                    selectedLinesWithProjectCreationRestricted
                  }
                  onClick={handleAddToGMAWBProject}
                  label="ADD TO GMAWB PROJECT"
                ></Button>
              </>
            )}

            {hasSelectedRows && !hasSelectedRowsWithDifferentApplicants && (
              <label className={classes["selected-rows-info"]}>
                {selectedRows.length} line/s selected
              </label>
            )}

            {hasSelectedRowsWithDifferentApplicants && (
              <label className={classes["error"]}>
                Cannot merge Order Lines with different FLEX Sold To (Party Site
                Number)
              </label>
            )}
          </div>
        </div>
      </div>

      <AssignFlexHandlerModal
        onSaveNewFlexHandler={handleAssignFlexHandlerModalSave}
        projectHandlers={projectHandlers}
        onHide={handleAssignFlexHandlerModalClose}
        visible={showAssignFlexHandlerModal}
      />

      {isProjectAlreadyExistsModalVisible && (
        <ProjectAlreadyExistsModal
          visible={isProjectAlreadyExistsModalVisible}
          onCancel={handleProjectAlreadyExistsModalCancel}
          onProceed={handleProjectAlreadyExistsModalProceed}
          accountNo={selectedAccountNo}
          partySiteNumber={selectedPartySiteNumber}
        />
      )}

      <ProjectWorkQueueModal
        isExistingGMAWBProject={hasSelectedProjectId}
        visible={isCreateProjectModalVisible}
        closeModal={handleCloseCreateE2EProjectModal}
        certificateSchemeList={certificateSchemeList}
        handleAddOrderLine={handleAddOrderLine}
        setIsCdpSaved={setIsCdpSaved}
      />

      <UnsupportedFlexTemplateModal
        visible={showFlexUnsupportedTemplateListModal}
        unsupportedOrderLines={flexUnsupportedTemplateList}
        onConfirm={handleOnConfirmUnsupportedOrderLines}
        onCancel={() => {
          setShowFlexUnsupportedTemplateListModal(false);
          setFlexUnsupportedTemplateList([]);
          setUnsupportedTemplateDataProp(null);
        }}
        dataProp={unsupportedTemplateDataProp}
      />

      <ProjectCreationRestrictionModal
        onClose={onProjectCreationWarningIconClose}
        visible={showRestrictCreationMessageModal}
        projectCreationRestrictionModel={projectCreationRestrictions}
      />
    </>
  );
};

export default ProjectWorkQueue;
