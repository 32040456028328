import { ResultModel } from "../models/result.model";
import { SyncTimeEntryRequestModel } from "../pages/project-management-pmr/shared/interfaces/pmr-e2e-time-entry-sync-model";
import { E2EProjectLineECDAdjustmentModel } from "../shared/models/service-models/E2EProjectLineECDAdjustmentModel";
import { GetInvalidRRTaskRequestModel } from "../shared/models/service-models/GetInvalidRRTaskRequest.model";
import { GetInvalidRRTaskResult } from "../shared/models/service-models/GetInvalidRRTaskResult.model";
import { TimestampProjectLineMilestonesModel } from "../shared/models/service-models/TimestampProjectLineMilestones.model";
import { TimestampResultModel } from "../shared/models/service-models/TimestampResultModel";
import RequestFactory from "./RequestFactory";

const GMAE2EURL = process.env.REACT_APP_GMAE2E_URL;

const ProjectTimeEntryService = {
  getUserTimeEntriesByTaskAndDateRange: async (model: any) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/projecttimeentry/get-flextask-time-entry",
      model
    ),
  getUserTimeEntriesByDateRange: async (model: any) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/projecttimeentry/get-user-time-entry",
      model
    ),

  updateFlexTaskTimeEntry: async (model: any) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/projecttimeentry/update-flex-task-time-entry",
      model
    ),
  //returns all RR task that has no time entry
  validateTimeEntryRrTask: async (model: GetInvalidRRTaskRequestModel) =>
    (await RequestFactory.post(
      GMAE2EURL,
      "/api/projecttimeentry/validate-time-entry-rr-task",
      model
    )) as Promise<ResultModel<GetInvalidRRTaskResult[]>>,
    
  syncTimeEntryLevels: async (model: SyncTimeEntryRequestModel) =>
  (await RequestFactory.post(
    GMAE2EURL,
    "/api/projecttimeentry/sync-time-entry-levels",
    model
  ))
};

export default ProjectTimeEntryService;
