import React from "react";
import { GridColumnModel } from "../../../pages/project-management-pmr/data/project-grid-columns";
import classes from "./ShowHideColumnsMultiplePrimeDataTableItem.module.scss";
import {
  DataTable,
  DataTableProps,
  DataTableRowReorderParams,
} from "primereact/datatable";
import { Column, ColumnProps } from "primereact/column";
import clsx from "clsx";

interface ShowHideColumnsMultiplePrimeDataTableItemProps<T> {
  itemKey: T;
  fields: GridColumnModel[];
  hasShowAndFreezeSelection: boolean;
  scrollable: boolean;
  subHeader?: string;
  onRowReorder: (
    e: DataTableRowReorderParams,
    key: unknown,
    subHeader?: string
  ) => void;
  onShowColumnToggle: (
    key: unknown,
    columns: GridColumnModel[],
    subHeader?: string
  ) => void;
  displayFrozenCountDropdown: boolean;
  maxFrozenColumnCount: number;
  frozenColumnCount?: number;
  onFrozenColumnCountChange?: (
    event: React.ChangeEvent<HTMLSelectElement>,
    key: T
  ) => void;
}

const ShowHideColumnsMultiplePrimeDataTableItem = (
  props: ShowHideColumnsMultiplePrimeDataTableItemProps<unknown>
) => {
  const isSelectAll = props.fields
    .filter((field) => field.orderNoShowHide! > 0)
    .every((field) => field.isShown);
  const dynamicOptions = [...Array(props.maxFrozenColumnCount).keys()].map(
    (num: number, i: number) => {
      return (
        <option
          key={i}
          value={num}
          aria-selected={props.frozenColumnCount === num ? true : false}
        >
          {num}
        </option>
      );
    }
  );

  const rowClass = (data: GridColumnModel, options: any) => {
    if (data.orderNoShowHide === 0) return classes["display-none"];

    if (props.hasShowAndFreezeSelection) {
      const filterFrozenColumns = options?.props?.value.filter(
        (filterFrozenColumns: GridColumnModel) =>
          (filterFrozenColumns.isFrozen ||
            filterFrozenColumns?.frozenPermanent) &&
          filterFrozenColumns.orderNoShowHide! > 0
      );

      return {
        [classes.rowfrozen]: data.isFrozen || data?.frozenPermanent,
        [classes.lastFrozen]:
          filterFrozenColumns[filterFrozenColumns?.length - 1]?.id === data.id,
      };
    }
    return {};
  };

  const showColumn = (e: any) => {
    const { id, checked } = e.target;
    const columns = props.fields.map((f: GridColumnModel) => {
      if (f.id === id) {
        return {
          ...f,
          isShown: checked,
        };
      }
      return f;
    });

    props.onShowColumnToggle(props.itemKey, columns, props.subHeader);
  };

  const onSelectAll = (e: any) => {
    const { checked } = e.target;

    const columns = props.fields.map((f: GridColumnModel) => {
      if (checked) {
        return {
          ...f,
          isShown: true,
        };
      }

      if (f.isFrozen || !f.canHide || f.orderNoShowHide === 0) {
        return f;
      }

      return {
        ...f,
        isShown: false,
      };
    });

    props.onShowColumnToggle(props.itemKey, columns, props.subHeader);
  };

  const scrollableProperties: DataTableProps = {
    scrollable: props.scrollable,
    scrollHeight: props.scrollable ? "400px" : undefined,
    scrollDirection: props.scrollable ? "both" : undefined,
  };

  const singleLevelColumns: React.ReactElement[] = [
    <Column
      columnKey={`${props.itemKey} - ${new Date().toTimeString()}`}
      className={clsx(classes["drag-body"])}
      style={{ width: "30px" }}
      body={(colProps: GridColumnModel) => {
        if (!colProps.frozenPermanent)
          return (
            <i className="p-datatable-reorderablerow-handle pi pi-bars"></i>
          );
      }}
    ></Column>,
    <Column
      key="isShown"
      className={classes["checkbox-body"]}
      header={
        <input
          type="checkbox"
          className={classes["checkbox"]}
          onChange={onSelectAll}
          checked={isSelectAll}
        />
      }
      body={(rowData, column) => (
        <input
          type="checkbox"
          id={rowData.id}
          className={classes["checkbox"]}
          onChange={showColumn}
          checked={rowData.isShown}
          disabled={rowData.isFrozen || !rowData.canHide}
        />
      )}
      style={{ width: "40px" }}
    ></Column>,
    <Column
      key="orderNoShowHide"
      field="orderNoShowHide"
      header="Order"
      style={{ width: "70px"}}
    ></Column>,
    <Column
      key="value"
      field="value"
      header="Column Header"
      style={{
        flexGrow: 1,
        flexBasis: "110px",
      }}
    ></Column>,
  ];

  const multiLevelColumns: React.ReactElement[] = [
    <Column
      className={classes["drag-body"]}
      style={{ width: "1%" }}
      colSpan={3}
      header={props.subHeader}
      body={(colProps: GridColumnModel) => {
        if (!colProps.frozenPermanent)
          return (
            <i className="p-datatable-reorderablerow-handle pi pi-bars"></i>
          );
      }}
    ></Column>,
    <Column
      key="isShown"
      className={classes["checkbox-body"]}
      body={(rowData, column) => (
        <input
          type="checkbox"
          id={rowData.id}
          className={classes["checkbox"]}
          onChange={showColumn}
          checked={rowData.isShown}
          disabled={rowData.isFrozen || !rowData.canHide}
        />
      )}
      style={{ width: "1%" }}
    ></Column>,
    <Column
      headerStyle={{ display: "none" }}
      key="orderNoShowHide"
      field="orderNoShowHide"
      style={{ width: "10%" }}
    ></Column>,
    <Column
      key="value"
      field="value"
      headerStyle={{ display: "none" }}
      style={{width: "27%" }}
    ></Column>,
  ];

  return (
    <>
      {props.hasShowAndFreezeSelection && props.displayFrozenCountDropdown && (
        <>
          <div className={classes["freeze-column"]}>
            <span>
              <select
                style={{ paddingLeft: "5px", paddingRight: "5px" }}
                value={props.frozenColumnCount}
                onChange={(e) =>
                  props.onFrozenColumnCountChange
                    ? props.onFrozenColumnCountChange(e, props.itemKey)
                    : undefined
                }
              >
                {dynamicOptions}
              </select>
            </span>
            <span> Freeze Columns </span>
          </div>
        </>
      )}
      <DataTable
        {...scrollableProperties}
        removableSort
        value={props.fields}
        rowClassName={rowClass}
        size="small"
        showGridlines
        onRowReorder={(e) =>
          props.onRowReorder(e, props.itemKey, props.subHeader)
        }
        columnResizeMode="expand"
        responsiveLayout="scroll"
      >
        {props.subHeader && multiLevelColumns}

        {!props.subHeader && singleLevelColumns}
      </DataTable>
    </>
  );
};

export default ShowHideColumnsMultiplePrimeDataTableItem;
