import { useEffect, useRef, useState } from "react";
import {
  projectDetailsOrderlneListColumnAction,
  projectDetailsOrderlneListColumnAdditionalInfo,
  projectDetailsOrderlneListColumnApplicationType,
  projectDetailsOrderlneListColumnCertificationScheme,
  projectDetailsOrderlneListColumnCheckbox,
  projectDetailsOrderlneListColumnCrcd,
  projectDetailsOrderlneListColumnCustomerRemarks,
  projectDetailsOrderlneListColumnDeliveryPath,
  projectDetailsOrderlneListColumnEcd,
  projectDetailsOrderlneListColumnFlexHandler,
  projectDetailsOrderlneListColumnFlexTemplate,
  projectDetailsOrderlneListColumnGmaWbHandler,
  projectDetailsOrderlneListColumnGmawbTemplate,
  projectDetailsOrderlneListColumnLeadTime,
  projectDetailsOrderlneListColumnStatus,
  projectDetailsOrderlneListColumnTechnology,
  projectDetailsOrderlneListColumnUlRemarks,
  projectDetailsOrderlneListColumnPrice,
} from "../data/project-details-orderline-list-grid-columns";
import { GridColumnModel } from "../data/project-grid-columns";
import PmrE2EProjectDetailsOrderlineModel, {
  E2EProjectLineModelInfo,
  E2EProjectLineTechnology,
  getProjectLineIsCompleted,
  isRowCompleted,
  isRowUpdating,
} from "../shared/interfaces/pmr-e2e-project-details-orderline-model";
import ProjectService from "../../../services/ProjectService";
import classes from "./ProjectDetailsOrderLineList.module.scss";
import {
  DataTable,
  DataTableColReorderParams,
  DataTableColumnResizeEndParams,
} from "primereact/datatable";
import { Column, ColumnBodyOptions } from "primereact/column";
import { get, uniqBy } from "lodash";
import { formatDateWithTimezone } from "../../../shared/date-helper/DateHelper";
import ProjectOrderlineStatusTemplate from "../shared/components/project-orderline-status-template/ProjectOrderlineStatusTemplate";
import ProjectSingleCollaboratorAvatar from "../shared/components/project-single-collaborator-avatar/ProjectSingleCollaboratorAvatar";
import HandlerType from "../shared/enums/handler-type";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faArrowsRotate,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import ProjectOrderlineStatus from "../shared/enums/project-orderline-status";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { ProjectManagementPmrWrapper } from "../../../features/projectManagementPmr/prohectManagementPmrSliceInterfaces";
import {
  generateGlobalSearchFilters,
  selectShowHideColumnProjectsOrderLine,
  updateShowHideColumnByTable,
} from "../../../features/projectManagementPmr/projectManagementPmrSlice";
import ProjectOrderlineStatusBulkEditDialog from "../shared/components/project-orderline-status-bulk-edit-dialog/ProjectOrderlineStatusBulkEditDialog";
import clsx from "clsx";
import useToastr from "../../../hooks/useToastr";
import {
  selectForceReloadProject,
  selectOrderlineDetailList,
  updateForceReloadProject,
  updateRefreshProjectId,
  upsertOrderlineDetailsList,
} from "../../../features/projectManagement/projectManagementSlice";
import useProjectOrderlineDetail from "../../../hooks/useProjectOrderlineDetail";
import ProjectOrderlineEcdTemplate from "../shared/components/project-orderline-ecd-template/ProjectOrderlineEcdTemplate";
import PmrE2EProjectModel from "../shared/interfaces/pmr-e2e-project-model";
import {
  ProjectOrderlineMismatchECDDialog,
  MismatchECDDataModel,
} from "../shared/components/project-orderline-mismatch-ecd-dialog/ProjectOrderlineMismatchECDDialog";
import ProjectOrderlineRemarksTemplate from "../shared/components/project-orderline-remarks-template/ProjectOrderlineRemarksTemplate";
import RemarksTypesEnum from "../shared/components/remarks-history-dialog/RemarksTypeEnum";

interface ProjectDetailsOrderLineListProps {
  projectId: string;
  project: PmrE2EProjectModel;
}

const ProjectDetailsOrderLineList = (
  props: ProjectDetailsOrderLineListProps
) => {
  const dispatch = useDispatch();
  const datatableRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const selectOrderLineColumns = useSelector(
    selectShowHideColumnProjectsOrderLine
  );
  const [columns, setColumns] = useState<GridColumnModel[]>([]);
  const generatedGlobalFilters = useSelector<
    ProjectManagementPmrWrapper,
    { label: string; value?: any }[]
  >((state) => generateGlobalSearchFilters(state, props.projectId));

  const [selectAll, setSelectAll] = useState(false);
  const [isBulkEditDialogShow, setIsBulkEditDialogShow] = useState(false);
  const [
    isOrderlineMismatchECDDialogShow,
    setIsOrderlineMismatchECDDialogShow,
  ] = useState(false);

  const orderlineDetailList = useSelector(selectOrderlineDetailList);
  const orderLines =
    orderlineDetailList.find((list) => list.projectId === props.projectId)
      ?.orderlineDetailList || [];
  const projectOrderlineDetailHook = useProjectOrderlineDetail(props.projectId);

  const [mismatchECDData, setMismatchECDData] =
    useState<MismatchECDDataModel | null>(null);

  const { showSuccess, showError } = useToastr();

  const forceReloadProject = useSelector(selectForceReloadProject);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    setColumns(selectOrderLineColumns!.fields);
  }, [selectOrderLineColumns]);

  useEffect(() => {
    if (datatableRef.current) {
      datatableRef.current.restoreColumnWidths();
      datatableRef.current.reset();
    }
  }, [columns]);

  useEffect(() => {
    if (forceReloadProject === true) {
      getList();
      dispatch(updateForceReloadProject(false));
    }
  }, [forceReloadProject]);

  //#region apply globalSearch
  // TODO: can be done in backend
  const applyGlobalFilters = (line: any) =>
    [line.orderLineNumber, line.projectNumber, line.orderNumber].some(
      (lineComparer) => {
        return generatedGlobalFilters.some((itemFilter) => {
          const comparer = itemFilter.value ?? itemFilter.label;
          return (lineComparer ?? "")
            .trim()
            .toLowerCase()
            .includes(comparer.trim().toLowerCase());
        });
      }
    );

  //#endregion

  const onEcdUpdate = () => {
    getList();
    dispatch(updateRefreshProjectId(props.projectId));
  };

  const getList = async () => {
    setLoading(true);

    const response = await ProjectService.getOrderLineListDetails(
      props.projectId
    );

    if (response?.isSuccess && response?.data) {
      let records = formatList(response?.data);
      dispatch(
        upsertOrderlineDetailsList({
          projectId: props.projectId,
          orderlineDetailList: records,
        })
      );
    }
    setLoading(false);
  };

  const formatList = (list: PmrE2EProjectDetailsOrderlineModel[]) => {
    const productTypeKey = "productType";
    return list.map(
      (orderLine: PmrE2EProjectDetailsOrderlineModel, index: number) => {
        orderLine.isChecked = false;
        orderLine.orderLineCRCD = formatDateWithTimezone(
          orderLine.orderLineCRCD
        );
        orderLine.orderLineECD = formatDateWithTimezone(orderLine.orderLineECD);
        orderLine.previousECD = formatDateWithTimezone(orderLine.previousECD);
        orderLine.modelNameCsv = orderLine.e2EProjectLineModelInfos
          ?.map((model: E2EProjectLineModelInfo) => model.modelName)
          .join(", ");
        const uniqProductType = uniqBy(
          orderLine.e2EProjectLineModelInfos,
          productTypeKey
        );
        orderLine.productTypesCsv = uniqProductType
          ?.map((model: E2EProjectLineModelInfo) => model.productType)
          .sort((a, b) => a.localeCompare(b))
          .join(", ");
        orderLine.technologyCsv = orderLine.technologies
          ?.map(
            (technology: E2EProjectLineTechnology) => technology.technologyName
          )
          .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
          .join(", ");
        orderLine.orderLineNumber = setOrderLineNumber(orderLine);
        return orderLine;
      }
    );
  };

  const setOrderLineNumber = (
    orderLine: PmrE2EProjectDetailsOrderlineModel
  ) => {
    return orderLine?.orderLineNumber;
  };

  const isBulkActionRow = (rowData: PmrE2EProjectDetailsOrderlineModel) =>
    rowData.id == "0";

  const handleSelectAllCheck = (event: CheckboxChangeParams) => {
    const isChecked = event.checked;
    setSelectAll(isChecked);
    projectOrderlineDetailHook.updateSelectAllCheck(isChecked);
  };

  const handleRowCheck = (
    event: CheckboxChangeParams,
    rowData: PmrE2EProjectDetailsOrderlineModel
  ) => {
    projectOrderlineDetailHook.updateRowCheck(rowData, event.checked);
  };

  const tableColumnHeader = (col: GridColumnModel) => (
    <div>
      <span title={col.value}>{col.value}</span>
    </div>
  );

  const tableColumnBodyDefaultTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    return (
      <div
        title={get(rowData, col.field)}
        className={clsx(
          `text-container-ellipsis`,
          getProjectLineIsCompleted(rowData.orderLineStatus) &&
            classes["project-line-is-completed"]
        )}
      >
        {get(rowData, col.field)}
      </div>
    );
  };

  const tableColumnBody = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    if (isBulkActionRow(rowData)) return <></>;
    return tableColumnBodyDefaultTemplate(rowData, col);
  };

  const checkboxColumnHeaderTemplate = (col: GridColumnModel) => {
    return (
      <>
        <Checkbox
          checked={selectAll}
          onChange={(event: CheckboxChangeParams) =>
            handleSelectAllCheck(event)
          }
          className={`${classes["project-checkbox"]}`}
        />
      </>
    );
  };

  const actionColumnHeaderTemplate = (col: GridColumnModel) => {
    return (
      <>
        <div className={`${classes["action-column-container"]}`}></div>
      </>
    );
  };

  const actionColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel
  ) => {
    return (
      <>
        {rowData.isMismatchECD && (
          <div className={`${classes["project-arrows-rotate-div"]}`}>
            <FontAwesomeIcon
              title="Click to view details"
              onClick={(event: any) => handleOrderLineSetMismatchECD(rowData)}
              icon={faArrowsRotate}
              className={`${classes["project-arrows-rotate"]}`}
            />
          </div>
        )}
      </>
    );
  };

  const checkboxColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel
  ) => {
    if (isBulkActionRow(rowData)) return <></>;
    return (
      <>
        <div className={`${classes["project-checkbox-div"]}`}>
          {!getProjectLineIsCompleted(rowData.orderLineStatus) && (
            <Checkbox
              checked={rowData.isChecked}
              onChange={(event: CheckboxChangeParams) =>
                handleRowCheck(event, rowData)
              }
              disabled={
                rowData.orderLineStatus === ProjectOrderlineStatus.Completed
              }
              style={{
                opacity:
                  rowData.orderLineStatus === ProjectOrderlineStatus.Completed
                    ? 0.5
                    : 1,
              }}
              className={`${classes["project-checkbox"]}`}
            />
          )}

          {getProjectLineIsCompleted(rowData.orderLineStatus) && (
            <span className={classes["check-icon"]}>
              <FontAwesomeIcon icon={faCircleCheck} />
            </span>
          )}
        </div>
      </>
    );
  };

  const statusColumnHeaderTemplate = (col: GridColumnModel) => {
    return (
      <>
        <div className={`${classes["action-column-container"]}`}>
          <span>Order Line Status</span>
          <FontAwesomeIcon
            title="Bulk Edit"
            icon={faEdit}
            style={{ marginLeft: "5px", cursor: "pointer" }}
            onClick={() => {
              if (validSelectedRows().length > 0) setIsBulkEditDialogShow(true);
            }}
          />
        </div>
      </>
    );
  };

  const flexTemplateColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    if (isBulkActionRow(rowData))
      return (
        <>
          <MultiSelect options={[]} placeholder="--Select--" disabled={true} />
        </>
      );
    return tableColumnBodyDefaultTemplate(rowData, col);
  };

  const gmawbTemplateColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    if (isBulkActionRow(rowData))
      return (
        <>
          <MultiSelect options={[]} placeholder="--Select--" disabled={true} />
        </>
      );
    return tableColumnBodyDefaultTemplate(rowData, col);
  };

  const statusColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel
  ) => {
    if (isBulkActionRow(rowData))
      return (
        <>
          <MultiSelect options={[]} placeholder="--Select--" disabled={true} />
        </>
      );
    return (
      <ProjectOrderlineStatusTemplate
        status={
          rowData[projectDetailsOrderlneListColumnStatus] ??
          ProjectOrderlineStatus.NotDefined
        }
        orderlineDetail={rowData}
        reloadList={getList}
        projectId={props.projectId}
      ></ProjectOrderlineStatusTemplate>
    );
  };

  const ecdColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    if (isBulkActionRow(rowData))
      return (
        <>
          <Calendar
            yearRange={"2020:3000"}
            showButtonBar={true}
            monthNavigator={true}
            yearNavigator={true}
            showIcon={true}
            disabled={true}
          ></Calendar>
        </>
      );

    return (
      <ProjectOrderlineEcdTemplate
        project={props.project}
        orderlineDetail={rowData}
        reloadList={onEcdUpdate}
      />
    );
  };

  const crcdColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    if (isBulkActionRow(rowData))
      return (
        <>
          <Calendar
            yearRange={"2020:3000"}
            showButtonBar={true}
            monthNavigator={true}
            yearNavigator={true}
            showIcon={true}
            disabled={true}
          ></Calendar>
        </>
      );
    return (
      <span onClick={() => alert()}>
        {tableColumnBodyDefaultTemplate(rowData, col)}
      </span>
    );
  };

  const priceColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    if (isBulkActionRow(rowData))
      return (
        <>
          <InputText disabled={true} />
        </>
      );

    if (!rowData.price || rowData.price === "-" || !rowData.currency) {
      return <></>;
    }

    const formattedPrice = parseFloat(rowData.price).toFixed(2);
    const priceWithCurrency = `${formattedPrice} ${rowData.currency}`;

    return (
      <>
        <span
          className={clsx(
            getProjectLineIsCompleted(rowData.orderLineStatus) &&
              classes["project-line-is-completed"]
          )}
        >
          {priceWithCurrency}
        </span>
      </>
    );
  };

  const flexHandlerCollaboratorColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel
  ) => {
    if (isBulkActionRow(rowData)) return <></>;
    return (
      <ProjectSingleCollaboratorAvatar
        name={get(rowData, projectDetailsOrderlneListColumnFlexHandler) ?? ""}
        handlerType={HandlerType.FlexHandler}
      ></ProjectSingleCollaboratorAvatar>
    );
  };

  const gmaWBHandlerCollaboratorColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel
  ) => {
    if (isBulkActionRow(rowData)) return <></>;
    return (
      <ProjectSingleCollaboratorAvatar
        name={get(rowData, projectDetailsOrderlneListColumnGmaWbHandler) ?? ""}
        handlerType={HandlerType.GmaWBHandler}
      ></ProjectSingleCollaboratorAvatar>
    );
  };

  const certificationSchemeColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    if (isBulkActionRow(rowData))
      return (
        <>
          <MultiSelect options={[]} placeholder="--Select--" disabled={true} />
        </>
      );
    return tableColumnBodyDefaultTemplate(rowData, col);
  };

  const applicationTypeSchemeColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    if (isBulkActionRow(rowData))
      return (
        <>
          <MultiSelect options={[]} placeholder="--Select--" disabled={true} />
        </>
      );
    return tableColumnBodyDefaultTemplate(rowData, col);
  };

  const additionalInfoColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    if (isBulkActionRow(rowData))
      return (
        <>
          <MultiSelect options={[]} placeholder="--Select--" disabled={true} />
        </>
      );
    return tableColumnBodyDefaultTemplate(rowData, col);
  };

  const deliveryPathColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    if (isBulkActionRow(rowData))
      return (
        <>
          <MultiSelect options={[]} placeholder="--Select--" disabled={true} />
        </>
      );
    return tableColumnBodyDefaultTemplate(rowData, col);
  };

  const leadTimeColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    if (isBulkActionRow(rowData))
      return (
        <>
          <InputText disabled={true} />
        </>
      );
    return tableColumnBodyDefaultTemplate(rowData, col);
  };

  const technologyColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    if (isBulkActionRow(rowData))
      return (
        <>
          <MultiSelect options={[]} placeholder="--Select--" disabled={true} />
        </>
      );
    return tableColumnBodyDefaultTemplate(rowData, col);
  };

  const ulRemarksColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    if (isBulkActionRow(rowData)) return <></>;

    return (
      <ProjectOrderlineRemarksTemplate
        orderlineDetail={rowData}
        remarkType={RemarksTypesEnum.ULRemarks}
        latestRemarks={rowData.ulRemarks}
      />
    );
  };

  const customerRemarksColumnBodyTemplate = (
    rowData: PmrE2EProjectDetailsOrderlineModel,
    col: ColumnBodyOptions
  ) => {
    if (isBulkActionRow(rowData))
      return (
        <>
          <InputText disabled={true} />
        </>
      );
    return (
      <ProjectOrderlineRemarksTemplate
        orderlineDetail={rowData}
        remarkType={RemarksTypesEnum.CustomerRemarks}
        latestRemarks={rowData.customerRemarks}
      />
    );
  };

  const processColumnHeaderTemplates = (col: GridColumnModel) => {
    if (col.id === projectDetailsOrderlneListColumnCheckbox)
      return checkboxColumnHeaderTemplate(col);
    if (col.id === projectDetailsOrderlneListColumnAction)
      return actionColumnHeaderTemplate(col);
    if (col.id === projectDetailsOrderlneListColumnStatus)
      return statusColumnHeaderTemplate(col);
    return tableColumnHeader(col);
  };

  const processColumnBodyTemplates = (col: GridColumnModel) => {
    if (col.id === projectDetailsOrderlneListColumnCheckbox)
      return checkboxColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnAction)
      return actionColumnBodyTemplate.bind(col);
    if (col.id == projectDetailsOrderlneListColumnFlexTemplate)
      return flexTemplateColumnBodyTemplate.bind(col);
    if (col.id == projectDetailsOrderlneListColumnGmawbTemplate)
      return gmawbTemplateColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnStatus)
      return statusColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnEcd)
      return ecdColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnCrcd)
      return crcdColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnFlexHandler)
      return flexHandlerCollaboratorColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnGmaWbHandler)
      return gmaWBHandlerCollaboratorColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnCertificationScheme)
      return certificationSchemeColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnApplicationType)
      return applicationTypeSchemeColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnAdditionalInfo)
      return additionalInfoColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnDeliveryPath)
      return deliveryPathColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnLeadTime)
      return leadTimeColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnTechnology)
      return technologyColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnUlRemarks)
      return ulRemarksColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnCustomerRemarks)
      return customerRemarksColumnBodyTemplate.bind(col);
    if (col.id === projectDetailsOrderlneListColumnPrice)
      return priceColumnBodyTemplate.bind(col);

    return tableColumnBody.bind(col);
  };

  const dynamicColumns = columns.map((col: GridColumnModel) => {
    const isCheckboxOrActionColumn =
      col.id === projectDetailsOrderlneListColumnCheckbox ||
      col.id === projectDetailsOrderlneListColumnAction;
    return (
      <Column
        header={processColumnHeaderTemplates(col)}
        field={col.id}
        hidden={!col.isShown}
        style={
          col.isFlexWidth
            ? {
                flexGrow: 1,
                flexBasis: col.width,
                width: col.width,
                minWidth: col.minWidth ?? 80,
              }
            : {
                width: col.width,
                minWidth: col.isFrozen ? col.width : 50,
                maxWidth: isCheckboxOrActionColumn ? col.width : undefined,
              }
        }
        body={processColumnBodyTemplates(col)}
        sortable={col.orderBy != ""}
        align={col.align ?? "left"}
        alignHeader={col.align ?? "left"}
        reorderable={!col.isColumnNotReordable && !col.isFrozen}
        frozen={col.isFrozen}
      />
    );
  });

  const onColReorder = (e: DataTableColReorderParams) => {
    if (e.dropIndex < 2) {
      datatableRef.current.reset();
    } else {
      let prevNumber = 0;
      let pwqColumns = columns
        .map((col, i) => {
          const cols: any = e.columns;
          const orderNo =
            cols.findIndex((fi: any) => fi.props.field === col.id) + 1;
          return {
            ...col,
            orderNo,
          };
        })
        .sort(function (a, b) {
          return a.orderNo - b.orderNo;
        })
        .map((col) => {
          if (col.orderNoShowHide! > 0) prevNumber++;

          const orderNoShowHide = col.orderNoShowHide! > 0 ? prevNumber : 0;

          return {
            ...col,
            orderNoShowHide,
          };
        })
        .sort((col) => col.orderNoShowHide)
        .map((col) => {
          if (
            col.orderNoShowHide <= selectOrderLineColumns!.frozenColumnCount
          ) {
            return {
              ...col,
              isShown: true,
              isFrozen: true,
            };
          } else {
            return {
              ...col,
              isFrozen: false,
            };
          }
        });

      dispatch(
        updateShowHideColumnByTable({
          key: selectOrderLineColumns?.key,
          newColumns: pwqColumns,
          frozenCount: selectOrderLineColumns?.frozenColumnCount,
        })
      );
    }
  };

  const onColResize = (e: DataTableColumnResizeEndParams) => {
    let newColumns: any = columns.map((col, i) => {
      if (e.column.props.field == col.id) {
        return {
          ...col,
          width: e.element.offsetWidth,
        };
      } else {
        return col;
      }
    });
    dispatch(
      updateShowHideColumnByTable({
        key: selectOrderLineColumns?.key,
        newColumns: newColumns,
      })
    );
  };

  const getRowUpdatingClassName = (
    rowData: PmrE2EProjectDetailsOrderlineModel
  ) => {
    return clsx(isRowUpdating(rowData) && classes["is-updating"]);
  };

  const validSelectedRows = () => {
    return orderLines.filter((line) => line.isChecked && !isRowCompleted(line));
  };

  const handleOrderLineSetMismatchECD = async (
    rowData: PmrE2EProjectDetailsOrderlineModel
  ) => {
    try {
      const result = await ProjectService.updateProjectLineIsMismatchECD(
        rowData.id
      );

      if (!result.isSuccess) return showError("Error", result.message);

      let data: MismatchECDDataModel = {
        projectNumber: rowData.projectNumber,
        orderLineECD: rowData.orderLineECD,
        orderLineNumber: rowData.orderLineNumber,
        previousECD: rowData.previousECD,
      };

      setMismatchECDData(data);
      setIsOrderlineMismatchECDDialogShow(true);
    } catch {
      showError(
        "Error",
        "There is an error processing your request. Please try again later."
      );
    }
  };

  return (
    <>
      <div className={`${classes["table-container"]}`}>
        <div
          className={`${classes["project-detail-orderline-list-grid-container"]}`}
        >
          <DataTable
            ref={datatableRef}
            emptyMessage="--- No results found. Please try different search criteria or check your spelling. ---"
            dataKey="id"
            value={
              generatedGlobalFilters.length > 0
                ? orderLines.filter(applyGlobalFilters)
                : orderLines
            }
            loading={loading}
            scrollable
            resizableColumns
            size="small"
            scrollDirection="both"
            columnResizeMode="fit"
            reorderableColumns
            onColReorder={onColReorder}
            onColumnResizeEnd={onColResize}
            rowClassName={(rowData) => getRowUpdatingClassName(rowData)}
          >
            {dynamicColumns}
          </DataTable>
        </div>
      </div>

      {setIsBulkEditDialogShow && (
        <ProjectOrderlineStatusBulkEditDialog
          closeModal={() => setIsBulkEditDialogShow(false)}
          visible={isBulkEditDialogShow}
          selectedRows={validSelectedRows()}
          reloadList={getList}
          projectId={props.projectId}
        />
      )}

      {isOrderlineMismatchECDDialogShow && (
        <ProjectOrderlineMismatchECDDialog
          visible={isOrderlineMismatchECDDialogShow}
          onClose={() => setIsOrderlineMismatchECDDialogShow(false)}
          data={mismatchECDData}
        />
      )}
    </>
  );
};

export default ProjectDetailsOrderLineList;
