export interface GridColumnModel {
  id: string;
  value: string;
  selected: boolean;
  orderBy: string;
  isShown: boolean;
  isFrozen: boolean;
  width: string;
  widthNum: number;
  orderNo: number;
}

export interface GridColumnModelList extends GridColumnModel {
  displayType: string;
}
export const pWqColumnProjectHandler = "project.projectHandler";
export const projectWorkQueueGridColumns: GridColumnModelList[] = [
  {
    id: "project.projectNumber",
    value: "Project Number",
    selected: false,
    orderBy: "project/projectNumber",
    isShown: true,
    isFrozen: true,
    width: "200px",
    widthNum: 180,
    orderNo: 1,
    displayType: "text",
  },
  {
    id: "project.name",
    value: "FLEX Project Name",
    selected: false,
    orderBy: "project/name",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 2,
    displayType: "text",
  },
  {
    id: "project.orderNumber",
    value: "Order Number",
    selected: false,
    orderBy: "project/orderNumber",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 3,
    displayType: "text",
  },
  {
    id: "lineNumberWithDescription",
    value: "Order Line Number",
    selected: false,
    orderBy: "lineNumber",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 4,
    displayType: "text",
  },
  {
    id: "soldToCompanyName",
    value: "FLEX Sold To (Customer/Company Name)",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 5,
    displayType: "text",
  },
  {
    id: "soldToPSN",
    value: "FLEX Sold To (PSN)",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 6,
    displayType: "text",
  },
  {
    id: "shipToCompanyName",
    value: "FLEX Ship To (Company Name)",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 7,
    displayType: "text",
  },
  {
    id: "shipToPSN",
    value: "FLEX Ship To (PSN)",
    selected: false,
    orderBy: "",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 8,
    displayType: "text",
  },
  {
    id: "project.endDate",
    value: "FLEX Project ECD",
    selected: false,
    orderBy: "project/endDate",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 9,
    displayType: "date",
  },
  {
    id: "projectLineEndDate",
    value: "Order Line ECD",
    selected: false,
    orderBy: "projectLineEndDate",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 10,
    displayType: "date",
  },
  {
    id: "priceWithCurrency",
    value: "Price",
    selected: false,
    orderBy: "price",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 11,
    displayType: "text",
  },
  {
    id: "projectLineStatus",
    value: "Order Line Status",
    selected: false,
    orderBy: "projectLineStatus",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 12,
    displayType: "text",
  },
  {
    id: pWqColumnProjectHandler,
    value: "FLEX Handler",
    selected: false,
    orderBy: "project/projectHandler",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 13,
    displayType: "text",
  },
  {
    id: "project.dateBooked",
    value: "Order Booked Date",
    selected: false,
    orderBy: "project/dateBooked",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 14,
    displayType: "date",
  },
  {
    id: "project.createdOn",
    value: "FLEX Project Created Date",
    selected: false,
    orderBy: "project/createdOn",
    isShown: true,
    isFrozen: false,
    width: "200px",
    widthNum: 180,
    orderNo: 15,
    displayType: "date",
  },
];
