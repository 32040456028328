import { useEffect, useState, useCallback, useRef } from "react";
import { randomString } from "../utils/helpers/string-format.helper";
import { debounce } from "lodash";
import { DataTablePFSEvent } from "primereact/datatable";

const usePwqLazyLoadDataTable = (
  initialItemsPerPageCount: number = 50,
  defaultSortField: string,
  dataTableRef: any,
  loadList: any,
  customSearchText: string,
  selectedRefiners: any,
  useCallbackDependencies: any[] = [],
  isLoadMore: boolean,
  sortList: any
) => {
  const lastScrollLeft = useRef(0);
  const defaultSortOrder = -1;
  const [sortField, setSortField] = useState<string>(defaultSortField);
  const [sortOrder, setSortOrder] = useState<any>(defaultSortOrder);
  const [scrollBotomEvent, setScrollBottomEvent] = useState("");
  const [currentPageNo, setCurrentPageNo] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(
    initialItemsPerPageCount
  );
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsShownCount, setRecordsShownCount] = useState(0);
  const [initialPwqDataCount, setInitialPwqDataCount] = useState(20);
  const [onLoadList, setOnLoadList] = useState("");
  const [isBcpMode, setIsBcpMode] = useState(false);
  const [cacheStartingPage, setCacheStartingPage] = useState(0);
  const [cacheEndingPage, setCacheEndingPage] = useState(0);

  const loadListDebounce = useCallback(
    debounce(loadList, 0),
    useCallbackDependencies || []
  );

  const gridWrapperElement = () => {
    const el = dataTableRef.current?.el;
    return el && el.children[0];
  };

  const footerElement = () => {
    const el = document.querySelectorAll(".p-datatable-footer");
    return el && el[0];
  };

  useEffect(() => {
    if (!onLoadList) return;
    if (recordsShownCount < initialPwqDataCount && isLoadMore)
      loadList(
        false,
        customSearchText,
        selectedRefiners,
        sortField,
        sortOrder,
        cacheStartingPage,
        cacheEndingPage
      );
  }, [onLoadList]);

  useEffect(() => {
    if (gridWrapperElement()) {
      const gridWrapper = gridWrapperElement();

      //Remove existing listener to be sure no duplicate
      gridWrapper.removeEventListener("scroll", handleScroll, true);

      bindLazyLoadDebounce();
      handleFooterWidth(100);
      return () => {
        gridWrapper.removeEventListener("scroll", handleScroll, true);
      };
    }
  });

  useEffect(() => {
    const el: Element | null = document.querySelector(".pwq-table-container");
    if (el) {
      const resizeObserver = new ResizeObserver((event) => {
        handleFooterWidth(300);
      });
      resizeObserver.observe(el);
    }
  }, []);

  const handleFooterWidth = (timeout: number) => {
    setTimeout(() => {
      const gridWrapper = gridWrapperElement();
      let footer: any = footerElement();
      if (gridWrapper && footer)
        footer.style.width = `${gridWrapper.clientWidth}px`;
    }, timeout);
  };

  const bindLazyLoad = async () => {
    if (gridWrapperElement()) {
      const gridWrapper = gridWrapperElement();
      gridWrapper.addEventListener("scroll", handleScroll, true);
    }
  };

  const handleScroll = () => {
    if (gridWrapperElement()) {
      const gridWrapper = gridWrapperElement();
      let documentScrollLeft = gridWrapper.scrollLeft;
      if (lastScrollLeft.current != documentScrollLeft) {
        //scrolling horizontal
        lastScrollLeft.current = documentScrollLeft;
      } else {
        //scrolling vertical
        if (
          gridWrapper.scrollHeight -
            gridWrapper.scrollTop -
            gridWrapper.offsetHeight <
          1
        ) {
          scrollBottomDebounce();
        }
      }
    }
  };

  const bindLazyLoadDebounce = useCallback(debounce(bindLazyLoad, 1000), []);

  const scrollBottomProcess = async () => {
    setScrollBottomEvent(randomString());
  };

  const scrollBottomDebounce = useCallback(
    debounce(scrollBottomProcess, 300),
    []
  );

  useEffect(() => {
    if (!scrollBotomEvent) return;
    if (isLazyLoading) return;
    if (isLoading) return;
    if (!showLoadMore()) return;
    loadList(
      false,
      customSearchText,
      selectedRefiners,
      sortField,
      sortOrder,
      cacheStartingPage,
      cacheEndingPage
    );
  }, [scrollBotomEvent]);

  const resetScroll = () => {
    if (gridWrapperElement()) {
      dataTableRef.current.resetScroll();
      lastScrollLeft.current = 0;
    }
  };

  const handleSort = (event: DataTablePFSEvent) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
    //resetScroll();
    sortList(event.sortField, event.sortOrder, true);
  };

  const reloadList = () => {
    resetScroll();
    loadList(
      true,
      customSearchText,
      selectedRefiners,
      sortField,
      sortOrder,
      cacheStartingPage,
      cacheEndingPage
    );
  };

  const getSetPageNo = (isResetPage: boolean): number => {
    const pageNo = isResetPage ? 1 : currentPageNo + 1;
    setCurrentPageNo(pageNo);
    return pageNo;
  };

  const showLoadMore = (): boolean => {
    const itemsCountFromFlex = itemsPerPage * currentPageNo;
    return itemsCountFromFlex < totalRecords;
  };

  const loadedList = (): void => {
    setOnLoadList(randomString());
  };

  return {
    resetScroll,
    scrollBotomEvent,
    handleSort,
    sortField,
    sortOrder,
    currentPageNo,
    itemsPerPage,
    reloadList,
    setScrollBottomEvent,
    isLazyLoading,
    setIsLazyLoading,
    isLoading,
    setIsLoading,
    getSetPageNo,
    totalRecords,
    setTotalRecords,
    showLoadMore,
    recordsShownCount,
    setRecordsShownCount,
    loadListDebounce,
    defaultSortField,
    defaultSortOrder,
    setSortField,
    setSortOrder,
    loadedList,
    isBcpMode,
    setIsBcpMode,
    cacheStartingPage,
    setCacheStartingPage,
    cacheEndingPage,
    setCacheEndingPage,
    setItemsPerPage,
  };
};

export default usePwqLazyLoadDataTable;
