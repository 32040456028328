import {
  Calendar,
  CalendarChangeParams,
  CalendarProps,
} from "primereact/calendar";
import classes from "./PmrDaterangeRefiner.module.scss";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { isValidNumber } from "../../../../../utils/helpers/object.helpers";
import { RadioButton, RadioButtonChangeParams } from "primereact/radiobutton";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { useFormikContext } from "formik";

export interface PmrDaterangeRefinerProps {
  /**
   * Formik parent name
   */
  name: string;
  /**
   * Use only with formik
   */
  showCheckboxDate?: boolean;
  onChange?: (
    value:
      | Event
      | React.SyntheticEvent<Element, Event>
      | RadioButtonChangeParams
      | CheckboxChangeParams
  ) => void;
  /**
   * Formik parent value
   */
  values?: any;
  fromName?: string;
  toName?: string;
}

export interface PmrDaterangeModel {
  pastDue?: boolean | null;
  from: Date | null;
  to: Date | null;
}

const checkBoxDates = [
  { label: "Past Due", value: "pastDue" },
  { label: "Due This Week", value: "dueThisWeek" },
  { label: "Due This Month", value: "dueThisMonth" },
];

export const PmrDaterangeSelectedRefinerTemplate = ([key, value]: any[]) => {
  let caption = "";
  switch (key) {
    case "from":
      caption = "From";
      break;
    case "to":
      caption = "To";
      break;
    default:
      const checboxDate = checkBoxDates.find((item) => item.value === value);
      return checboxDate?.label;
  }

  const momentValue = moment(value);
  return `${caption} ${momentValue.format("L")}`;
};

const defaultMinYear = 2016;
const defaultMaxYear = 2034;

export default function PmrDaterangeRefiner(props: PmrDaterangeRefinerProps) {
  const { getFieldHelpers } = useFormikContext<any>();
  const { name, showCheckboxDate, onChange, values, fromName, toName } = props;
  const fromId = `${name}-from`;
  const toId = `${name}-to`;
  const finalFromName = fromName ?? "from";
  const finalToName = toName ?? "to";

  const defaultCalendarProps: CalendarProps = {
    dateFormat: "yy-mm-dd",
    placeholder: "yyyy-mm-dd",
    mask: "9999-99-99",
    showButtonBar: true,
    monthNavigator: true,
    yearNavigator: true,
    showIcon: true,
    showOnFocus: false,
    panelClassName: classes["calendar-custom-panel"],
  };
  const [tempValues, setTempValues] = useState<PmrDaterangeModel>({
    from: null,
    to: null,
  });
  const fromRef = useRef<HTMLInputElement>(null);
  const toRef = useRef<HTMLInputElement>(null);
  const calendarSelectEvent = "calendar-select";

  const fromYear = tempValues.from ? tempValues.from.getFullYear() : null;
  const toYear = tempValues.to ? tempValues.to.getFullYear() : null;

  const getYearRangeFrom = () => {
    let min = fromYear ?? toYear ?? defaultMinYear;
    let max = toYear ?? fromYear ?? defaultMaxYear;

    if (fromYear || toYear) min -= 10;
    if (fromYear) max += 10;

    if (min < defaultMinYear) min = defaultMinYear;

    return `${min}:${max}`;
  };

  const getYearRangeTo = () => {
    let min = fromYear ?? toYear ?? defaultMinYear;
    let max = toYear ?? fromYear ?? defaultMaxYear;

    if (fromYear || toYear) max += 10;
    if (toYear) min -= 10;

    if (min < defaultMinYear) min = defaultMinYear;

    return `${min}:${max}`;
  };

  const handleCalendarChange = (event: CalendarChangeParams) => {
    if (event.originalEvent.type === "click") return;
    const originalValue = (
      ((event.originalEvent as any)?.target?.value ?? "") as string
    )
      .replace(/\-/g, "")
      .trim();

    if (originalValue === "") {
      calendarSubmit(event.originalEvent);
      return;
    }

    if (!isValidNumber(originalValue)) return;

    const originalMomentValue = moment(originalValue);

    if (event.value && originalMomentValue.isValid()) {
      calendarSubmit(event.originalEvent);
    }
  };

  const handleCalendarSelect = (name: "from" | "to") => {
    const calendarSelectEventInstance = new Event(calendarSelectEvent);
    switch (name) {
      case "from":
        fromRef.current?.dispatchEvent(calendarSelectEventInstance);
        break;
      case "to":
        toRef.current?.dispatchEvent(calendarSelectEventInstance);
        break;
    }
    return;
  };

  const calendarSubmit = (
    event: Event | React.SyntheticEvent<Element, Event> | CheckboxChangeParams
  ) => {
    if (onChange) onChange(event);
  };

  const handleCheckboxChange = (event: CheckboxChangeParams) => {
    const input = getFieldHelpers(`${name}`);
    input.setValue({
      due: event.checked ? event.value : undefined,
    });
  };

  useEffect(() => {
    const { from, to } = values ?? {
      from: null,
      to: null,
    };

    const momentFromValue = moment(from ?? ""),
      momentToValue = moment(to ?? "");

    setTempValues((prev) => ({
      ...prev,
      from: momentFromValue.isValid() ? momentFromValue.toDate() : null,
      to: momentToValue.isValid() ? momentToValue.toDate() : null,
    }));
  }, [values]);

  useEffect(() => {
    if (fromRef.current) {
      fromRef.current.addEventListener(calendarSelectEvent, calendarSubmit);
    }
    if (toRef.current) {
      toRef.current.addEventListener(calendarSelectEvent, calendarSubmit);
    }
  }, [fromRef, toRef]);

  return (
    <div className={classes["daterange-container"]}>
      {showCheckboxDate &&
        checkBoxDates.map((item, key) => {
          const inputId = `${name}${key}`;
          return (
            <div className="p-field-checkbox" key={key}>
              <Checkbox
                inputId={inputId}
                name={`${name}.due`}
                value={item.value}
                checked={item.value === values?.due}
                onChange={handleCheckboxChange}
              />
              <label htmlFor={inputId}>{item.label}</label>
            </div>
          );
        })}

      <div className="p-field">
        <label htmlFor={fromId}>From</label>
        <Calendar
          {...defaultCalendarProps}
          inputId={fromId}
          name={`${name}.${finalFromName}`}
          onHide={() => handleCalendarSelect("from")}
          onChange={(event) => handleCalendarChange(event)}
          inputRef={fromRef}
          value={tempValues.from ?? undefined}
          maxDate={tempValues.to ?? undefined}
          yearRange={getYearRangeFrom()}
          disabled={!!values?.due}
        />
      </div>

      <div className="p-field">
        <label htmlFor={toId}>To</label>
        <Calendar
          {...defaultCalendarProps}
          inputId={toId}
          name={`${name}.${finalToName}`}
          onHide={() => handleCalendarSelect("to")}
          onChange={(event) => handleCalendarChange(event)}
          inputRef={toRef}
          value={tempValues.to ?? undefined}
          yearRange={getYearRangeTo()}
          minDate={tempValues.from ?? undefined}
          disabled={!!values?.due}
        />
      </div>
    </div>
  );
}
