import moment from "moment";
import { ResultModel } from "../models/result.model";
import {
  E2EProjectLineModel,
  E2EProjectModel,
} from "../shared/models/E2EProject.model";
import E2EProjectDetailsRequestModel from "../shared/models/service-models/E2EProjectDetailsRequest.model";
import { E2EProjectDetailsResultModel } from "../shared/models/service-models/E2EProjectDetailsResult.model";
import E2EProjectLineRequestModel from "../shared/models/service-models/E2EProjectLineRequest.model";
import E2EProjectRequestModel from "../shared/models/service-models/E2EProjectRequest.model";
import RequestFactory from "./RequestFactory";
import PmrE2EProjectModel from "../pages/project-management-pmr/shared/interfaces/pmr-e2e-project-model";
import { PaginatedResultModel } from "../shared/models/service-models/PaginatedResult.model";
import { UpdateProjectlineECDModel } from "../shared/models/service-models/UpdateProjectlineECDModel.model";
import { EditGmaWbProjectRequestModel } from "../shared/models/service-models/EditGmaWbProjectRequest.model";
import { AddRemarksModel } from "../shared/models/service-models/AddRemarks.model";
import { GetRemarksModel } from "../shared/models/service-models/GetRemarks.model";
import { CreateAdHocTaskModel } from "../shared/models/CreateAdHocTaskModel";
import { CreateFlexTaskCommentModel } from "../shared/models/CreateFlexTaskComment.model.";
import { FlexTaskCommentModel } from "../shared/models/FlexTaskDetail.model";
import { USE_PROJECT_HANDLER_MAPPING } from "../utils/constants/feature-flag.constants";
import { ProjectFullViewRequestModel } from "../shared/models/ProjectFullViewRequestModel";

const GMAE2EURL = process.env.REACT_APP_GMAE2E_URL;

const ProjectService = {
  getProjects: async (
    request: E2EProjectRequestModel,
    cancelSource: any = undefined
  ) => {
    return (await RequestFactory.post(
      GMAE2EURL,
      "/api/project/get-projects",
      request,
      () => {
        if (cancelSource === undefined) return undefined;

        return {
          cancelToken: cancelSource.token,
        } as any;
      }
    )) as Promise<ResultModel<PaginatedResultModel<PmrE2EProjectModel[]>>>;
  },
  getProjectsPmr: async (
    request: E2EProjectRequestModel,
    cancelSource: any = undefined
  ) => {
    return (await RequestFactory.post(
      GMAE2EURL,
      `/api/project/get-projects-pmr${
        USE_PROJECT_HANDLER_MAPPING ? "-v2" : ""
      }`,
      request,
      () => {
        if (cancelSource === undefined) return undefined;

        return {
          cancelToken: cancelSource.token,
        } as any;
      }
    )) as Promise<ResultModel<PaginatedResultModel<PmrE2EProjectModel[]>>>;
  },
  getProjectList: async (request: E2EProjectRequestModel) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project/get-project-list",
      request
    ),
  getProjectTasksByProject: async (
    request: any,
    cancelSource: any = undefined
  ) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project/get-project-tasks",
      request,
      () => {
        if (cancelSource === undefined) return undefined;

        return {
          cancelToken: cancelSource.token,
        } as any;
      }
    ),
  getProjectListByCustomer: async (
    accountNo?: string | null,
    partySiteNumber?: string | null
  ) =>
    (await RequestFactory.get(
      GMAE2EURL,
      `/api/project/get-project-list-by-customer?accountNumber=${accountNo}&partySiteNumber=${partySiteNumber}`
    )) as ResultModel<Array<E2EProjectModel>>,
  getProjectLineList: async (request: E2EProjectLineRequestModel) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project/get-project-line-list",
      request
    ),
  getProjectDetails: async (request: E2EProjectDetailsRequestModel) =>
    (await RequestFactory.get(
      GMAE2EURL,
      `/api/project/project-details?projectId=${request.projectId}&isFlexTaskOnly=${request.isFlexTaskOnly}`
    )) as E2EProjectDetailsResultModel,
  getCertificateSchemeList: async () =>
    await RequestFactory.get(
      GMAE2EURL,
      "/api/project/get-certificate-scheme-list"
    ),
  getE2ETemplateByFlexProject: async (flexProjectId?: string | null) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/project/get-e2e-template-by-flex-project?flexProjectId=${flexProjectId}`
    ),
  getE2ETemplate: async (request?: E2EProjectLineModel) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/project/get-e2e-template?certificateSchemeId=${request?.schemeId}&applicationTypeId=${request?.applicationTypeId}&deliveryPathId=${request?.deliveryPathId}&additionalInfoId=${request?.additionalInfoId}&flexProjectId=${request?.flexProjectId}`
    ),
  createProject: async (request: E2EProjectModel) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project/create-project",
      request
    ),
  addProjectLines: async (request: E2EProjectModel) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project/add-project-lines",
      request
    ),
  validateE2EProjectName: async (projectNameRequest: { projectName: string }) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project/validate-e2e-project-name",
      projectNameRequest
    ),
  constructProjectName: (applicantName: string): string => {
    const timestamp = moment(new Date()).format("MMDDyyyy");

    return applicantName + "_" + timestamp;
  },
  getProjectOrderline: async (request: any, cancelSource: any = undefined) =>
    await RequestFactory.post(
      GMAE2EURL,
      `/api/project/get-project-task-orderlines`,
      request,
      () => {
        if (cancelSource === undefined) return undefined;

        return {
          cancelToken: cancelSource.token,
        } as any;
      }
    ),
  getProjectMilestoneTask: async (
    request: any,
    cancelSource: any = undefined
  ) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project/get-project-milestones",
      request,
      () => {
        if (cancelSource === undefined) return undefined;

        return {
          cancelToken: cancelSource.token,
        } as any;
      }
    ),
  getGMAWBFlexProjects: async (projectId: string) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/project/get-gmawb-flex-projects/${projectId}`
    ),
  getGMAWBFlexProjectDetails: async (
    flexProjectId: string,
    projectId: string
  ) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/project/get-gmawb-flex-project-details?flexProjectId=${flexProjectId}&projectId=${projectId}`
    ),
  getProjectDetailsFlexDetails: async (projectId: string) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/project/get-flex-project-details?flexProjectId=${projectId}`
    ),
  getOrderLineListDetails: async (projectId: string) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/project/get-orderlines-details/${projectId}`
    ),
  getTaskOnHoldStatus: async () =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/flex-integration/get-task-hold-reasons`
    ),
  updateOrderlineFlexTaskStatus: async (data: any) =>
    await RequestFactory.post(
      GMAE2EURL,
      `/api/project/update-flex-task-orderline-status`,
      data
    ),
  updateOrderlineStatus: async (data: any) =>
    await RequestFactory.post(
      GMAE2EURL,
      `/api/project/update-projectlines-statuses`,
      data
    ),
  updateOrderlineECD: async (data: UpdateProjectlineECDModel) =>
    (await RequestFactory.post(
      GMAE2EURL,
      `/api/project/update-projectline-ecd`,
      data
    )) as Promise<ResultModel>,
  getProjectContributorsList: async (projectId: string) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/project/get-project-collaborators?projectId=${projectId}`
    ),
  deleteE2EProject: async (projectId: string) =>
    await RequestFactory.delete(
      GMAE2EURL,
      `/api/project/delete-project/${projectId}`
    ),
  getEditProjectDetails: async (projectId: string) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/project/get-edit-project-details?e2eProjectId=${projectId}`
    ),
  saveEditProjectDetails: async (request: EditGmaWbProjectRequestModel) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project/save-edit-project-details",
      request
    ),
  updateProjectLineIsMismatchECD: async (e2EProjectLineId: string) =>
    (await RequestFactory.patch(
      GMAE2EURL,
      `/api/project/update-projectline-is-mismatch-ecd/${e2EProjectLineId}`,
      null
    )) as Promise<ResultModel>,
  checkPermissionToRemoveOrderLines: async (request: Array<string>) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project/check-permission-remove-order-lines",
      request
    ),
  removeOrderLines: async (request: Array<string>) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project/remove-order-lines",
      request
    ),
  computeAdjustedEcdOnEditProject: async (request: E2EProjectModel) =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project/compute-adjusted-ecd-edit-project",
      request
    ),

  addRemarks: async (request: AddRemarksModel) =>
    await RequestFactory.post(GMAE2EURL, "/api/project/create-remark", request),

  getRemarks: async (request: GetRemarksModel) =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/project/get-remark?e2EProjectLineId=${request.e2EProjectLineId}&projectLineRemarksType=${request.projectLineRemarksType}`,
      request
    ),
  getE2EProjectLines: async (e2eProjectId: string) =>
    (await RequestFactory.get(
      GMAE2EURL,
      `/api/project/get-e2e-project-lines?e2eProjectId=${e2eProjectId}`
    )) as Promise<ResultModel<E2EProjectLineModel[]>>,
  getE2EProjectLinesCommonTasks: async (e2eProjectLineIds: string[]) =>
    (await RequestFactory.post(
      GMAE2EURL,
      "/api/project/get-e2e-project-lines-common-tasks",
      e2eProjectLineIds
    )) as Promise<ResultModel<string[]>>,
  getProjectDistinctMilestoneNames: async (e2eProjectId: string) =>
    (await RequestFactory.get(
      GMAE2EURL,
      `/api/project/get-project-distinct-milestone-names?e2eProjectId=${e2eProjectId}`
    )) as Promise<ResultModel<string[]>>,
  createAdHocTask: async (model: CreateAdHocTaskModel) =>
    (await RequestFactory.post(
      GMAE2EURL,
      "/api/project/create-ad-hoc-task",
      model
    )) as Promise<ResultModel>,
  createFlexTaskComment: async (
    model: CreateFlexTaskCommentModel
  ): Promise<ResultModel<FlexTaskCommentModel>> =>
    await RequestFactory.post(
      GMAE2EURL,
      "/api/project/create-flex-task-comment",
      model
    ),
  canUserAddE2EProjectLineFlexTaskComment: async (
    flexProjectTaskId: string
  ): Promise<ResultModel<boolean>> =>
    await RequestFactory.get(
      GMAE2EURL,
      `/api/project/can-user-add-e2e-project-line-flex-task-comment?flexProjectTaskId=${flexProjectTaskId}`
    ),

  setProjectFullViewCacheKey: async (
    payload: ProjectFullViewRequestModel
  ): Promise<ResultModel> =>
    await RequestFactory.post(
      GMAE2EURL,
      '/api/project/set-cache-project-full-view-key',
      payload
    ),

    getProjectFullViewCacheKey: async(cacheKey: string): Promise<ResultModel<string>> =>
      await RequestFactory.get(
        GMAE2EURL,
        `/api/project/get-cache-project-full-view-key?cacheKey=${cacheKey}`
      ),
};

export default ProjectService;
