import "./CertificateListHeader.scss";
import classes from "./CertificateListHeader.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import AddNewCertificate from "../add-new-certificate/AddNewCertificate";
import CertificateListShowHide from "../certificate-list-show-hide/CertificateListShowHide";
import CertificateListFavorites from "../certificate-list-favorites/CertificateListFavorites";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef } from "react";
import CertificateListService from "../../../../services/CertificateListService";
import certBulkImportColumns from "../../data/cert-bulk-import-columns";
import { useLocation } from "react-router-dom";
import DocumentService from "../../../../services/DocumentService";
import ProjectDetailsService from "../../../../services/ProjectDetailsService";
import { useDispatch } from "react-redux";
import {
  updateAutoTimestampTask,
  updateCertificateScheme,
  updateLineDetails,
  updateModelList,
  updateOrderLineNumbers,
  updateProdDetailsModelList,
  updateSelectedOrderLineNumber,
  updateSelectedProjectDetails,
} from "../../../../features/createCertificate/createCertificateSlice";
import AutoTimestampModal from "../../../../shared/auto-timestamp-modal/AutoTimestampModal";
import moment from "moment";
import { flexProjectContactRole } from "../../../../utils/constants/flex-project-contact-role.constants";
import ProjectManagementService from "../../../../services/ProjectManagementService";

const CertificateListHeader = (props) => {
  const {
    columns,
    frozenColCount,
    activeCount,
    expiringSoonCount,
    withDrawnCount,
    underRevisionCount,
    obsoleteCount,
    totalCertificateCount,
    favoriteName,
    favoriteList,
    onClickStatusFilter,
    updateCertListColumns,
    setCertListColumns,
    onSaveFavorite,
    onEditFavorite,
    onDeleteFavorite,
    onClickFavorites,
    onClearFilters,
    dataTableData,
    selectedRows,
    customFilters,
    refiners,
    statusRefiners,
    globalSearchText,
  } = props;

  const [displayAddNewCertModal, setDisplayAddNewCertModal] = useState(false);
  const [position, setPosition] = useState("center");
  const [passedCurrentStep, setPassedCurrentStep] = useState(null);
  const [isOpenAutotimestampModal, setIsOpenAutotimestampModal] =
    useState(false);
  const hidePrefereredWayStep =
    process.env
      .REACT_APP_FEATUREFLAG_ADD_CERTFICATE_REMOVE_PREFERRED_WAY_STEP ===
    "true";

  const exportOp = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const dialogFuncMap = {
    displayAddNewCertModal: setDisplayAddNewCertModal,
  };

  const onShowModal = (name) => {
    dialogFuncMap[`${name}`](true);
  };

  const onClickAddNewCert = (name, position) => {
    onShowModal(name);

    if (position) {
      setPosition(position);
    }
  };

  const onClickExportButton = (e) => {
    exportOp.current.toggle(e);
  };

  const onClickExportListView = () => {
    exportToSpecific("shownColumns");
  };

  const onClickExportAllColumns = () => {
    exportToSpecific("allColumns");
  };

  const exportToSpecific = (exportToSpreadSheetOptionParam) => {
    if (
      selectedRows &&
      Array.isArray(selectedRows) &&
      selectedRows.length > 0
    ) {
      exportToSpreadSheet(exportToSpreadSheetOptionParam, "selected");
    } else {
      exportToSpreadSheet(exportToSpreadSheetOptionParam, "all");
    }
  };

  const exportToSpreadSheet = async (
    exportToSpreadSheetOption,
    exportCertOptionsRadioParam
  ) => {
    let exportedColumns;

    switch (exportToSpreadSheetOption) {
      case "shownColumns":
        exportedColumns = columns.filter((i) => i.isShown).map((i) => i.id);
        break;
      case "bulkImportTemplate":
        exportedColumns = certBulkImportColumns;
        break;
      default:
        exportedColumns = columns
          .sort((a, b) => a.value.localeCompare(b.value))
          .map((i) => i.id);
        break;
    }

    let exportData =
      exportCertOptionsRadioParam === "selected" ? selectedRows : dataTableData;

    let refinersParam = refiners;

    if (refinersParam.length === 0) {
      refinersParam = statusRefiners;
    }

    let exportListToExcelModel = {
      columns: exportedColumns,
      customFilters: customFilters,
      globalSearchText: globalSearchText,
      refiners: refinersParam,
      selectedRows: exportData,
    };

    const response = await CertificateListService.exportToSpreadSheet(
      exportListToExcelModel
    );

    const dateFormat = moment(new Date()).format("MMDDYYYY_hhmmss");
    const data = new Blob([response]);
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      "GMAWB Certificate List_" + dateFormat + ".xlsx"
    );
    link.click();
  };

  useEffect(() => {
    if (!location.search) return;
    const params = new URLSearchParams(location.search);

    const packageId = params.get("packageId");

    if (!packageId) return;
    const getUserDetails = async (selectedProjectDetails) => {
      if (!selectedProjectDetails || !selectedProjectDetails.handlerName)
        return selectedProjectDetails;

      const response = await ProjectDetailsService.getUserDetails(
        selectedProjectDetails.handlerName
      );
      if (!response) return;

      const handlerCountry = response.split("-")[0];
      const handlerLocation = response.split("-")[1];

      selectedProjectDetails.handlerCountry = handlerCountry;
      selectedProjectDetails.handlerLocation = handlerLocation;
      return selectedProjectDetails;
    };

    const getLocalStorage = async () => {
      const response = await DocumentService.getLocalStorage(packageId);

      if (!response) return;

      const certDetails = response[0].addNewCertMilestoneDetails;

      const projectNumber = certDetails[0].projectNumber;
      const certificateScheme = certDetails[0].certSchemeName;
      const lineNumber = certDetails[0].orderLineNumber.substring(
        0,
        certDetails[0].orderLineNumber.indexOf("-") - 1
      );
      const model = certDetails[0].model;
      let tasks = JSON.parse(certDetails[0].tasks);
      const isCor = certDetails[0].isCor ?? false;
      if (!projectNumber) return;

      if (isCor) {
        const projectLines = [
          {
            project_no: projectNumber,
            line_no: lineNumber,
          },
        ];

        const projectDetails = {
          flexTaskId: 4,
          projectLines: JSON.stringify(projectLines),
        };

        const timeEntry = await ProjectManagementService.getFlexTotalHours(
          projectDetails
        );

        if (timeEntry.total_hours <= 0) {
          tasks = [];
        }
      }

      setPassedCurrentStep(hidePrefereredWayStep ? 2 : 3);
      dispatch(updateCertificateScheme(certificateScheme));
      dispatch(updateAutoTimestampTask(tasks));
      await remapModel(model);
      onClickAddNewCert("displayAddNewCertModal");
      populateProjectDetails(projectNumber, lineNumber);
    };

    const populateProjectDetails = async (projectNumber, lineNumber) => {
      const projectNumbers = await ProjectDetailsService.getProjectSuggestions(
        projectNumber
      );

      if (projectNumbers.length === 0) return;

      let updatedSelectedProjectDetails;

      const item = projectNumbers[0];
      const data = await ProjectDetailsService.getProjectDetail(
        item.project_Number
      );

      let tempLineDetails = [];
      data.value.forEach((d) => {
        //Filter - Get Child Lines
        const lineDatas = d.projectServiceLines.filter(
          (l) => l.lineNumber.split(".").length === 3
        );

        if (lineDatas.length === 0) {
          return;
        }

        for (var i = 0; i < lineDatas.length; i++) {
          const projectContacts = getProjectContact(d.projectContacts);

          lineDatas[i].partySiteNumber = projectContacts.partySiteNumber;
          lineDatas[i].companyId = d.companyId;
          lineDatas[i].address = projectContacts.address;
          lineDatas[i].city = projectContacts.city;
          lineDatas[i].state = projectContacts.state;
          lineDatas[i].province = projectContacts.province;
          lineDatas[i].country = projectContacts.country;
          lineDatas[i].postalcode = projectContacts.postalCode;
          lineDatas[i].partysite = projectContacts.partySiteNumber;
          lineDatas[i].serviceLine = item.service_Line;
          lineDatas[i].customer = d.companyName;
          lineDatas[i].poc = projectContacts.country;
          lineDatas[i].handlerName = d.projectHandler;
          lineDatas[i].fulfillmentLocation =
            item.preferred_Fulfillment_Location;
          lineDatas[i].projectFlexId = d.projectId;
          lineDatas[i].projectNumber = d.projectNumber;
          lineDatas[i].orderNumber = d.orderNumber;
          lineDatas[i].quoteNo = d.quoteNo;
          lineDatas[i].projectName = d.name;
        }

        tempLineDetails = tempLineDetails.concat(lineDatas);
      });
      tempLineDetails = tempLineDetails.filter((l) => l != null);

      const lineNumbers = tempLineDetails.map((d) => d.lineNumber);
      const sortedLineNumbers = lineNumbers
        .map((a) =>
          a
            .split(".")
            .map((n) => +n + 100000)
            .join(".")
        )
        .sort()
        .map((a) =>
          a
            .split(".")
            .map((n) => +n - 100000)
            .join(".")
        );

      const mappedLines = sortedLineNumbers.map((d) => {
        return {
          name:
            d +
            " - " +
            tempLineDetails.find((l) => l.lineNumber === d)?.projectName,
          value: d,
        };
      });

      const selectedProjectDetails = tempLineDetails.find(
        (t) => t.lineNumber === lineNumber
      );

      if (selectedProjectDetails) {
        updatedSelectedProjectDetails = await getUserDetails(
          selectedProjectDetails
        );
      } else {
        updatedSelectedProjectDetails = await getUserDetails(
          tempLineDetails[0]
        );
      }

      dispatch(updateLineDetails(tempLineDetails));
      dispatch(updateOrderLineNumbers(mappedLines));
      dispatch(updateSelectedProjectDetails(updatedSelectedProjectDetails));
      dispatch(updateSelectedOrderLineNumber(lineNumber));
    };

    const remapModel = async (model) => {
      const remapModels = await CertificateListService.remapModel({
        models: model,
      });

      if (!remapModels) return;

      dispatch(updateProdDetailsModelList(remapModels));
      dispatch(updateModelList(remapModels));
    };

    getLocalStorage();
  }, [location]);

  const getProjectContact = (projectContacts) => {
    const soldTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.SoldTo
    );
    if (soldTo?.partySiteNumber) return soldTo;

    const shipTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.ShipTo
    );
    if (shipTo?.partySiteNumber) return shipTo;

    const customer = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.Customer
    );
    if (customer?.partySiteNumber) return customer;

    const billTo = projectContacts.find(
      (pc) => pc.contactRoleId === flexProjectContactRole.BillTo
    );
    if (billTo?.partySiteNumber) return billTo;
  };

  return (
    <>
      <AddNewCertificate
        inputAttachments={[]}
        dialogFuncMap={dialogFuncMap}
        displayAddNewCertModal={displayAddNewCertModal}
        position={position}
        passedCurrentStep={passedCurrentStep}
        setPassedCurrentStep={setPassedCurrentStep}
        setIsOpenAutotimestampModal={setIsOpenAutotimestampModal}
      />
      <div className="cert-list-header">
        <div className="clickable-status-filters-container">
          <div className="total-certs-div">
            {totalCertificateCount} Certificates
          </div>
          <div>
            <span
              className="active-cert-count clickable-status-filters"
              onClick={() => onClickStatusFilter("Active")}
            >
              <span>{activeCount}</span>
              <span> Active</span>
            </span>
            <span
              className="expiring-soon-count clickable-status-filters"
              onClick={() => onClickStatusFilter("Expiring Soon")}
            >
              <span>(</span>
              <span>{expiringSoonCount}</span>
              <span> Expiring Soon)</span>
            </span>
            <span
              className="under-revision-count clickable-status-filters"
              onClick={() => onClickStatusFilter("Under Revision")}
            >
              <span>{underRevisionCount}</span>
              <span> Under Revision</span>
            </span>
            <span
              className="obsolete-count clickable-status-filters"
              onClick={() => onClickStatusFilter("Obsolete")}
            >
              <span>{obsoleteCount}</span>
              <span> Obsolete</span>
            </span>
            <span
              className="withdrawn-count clickable-status-filters"
              onClick={() => onClickStatusFilter("Withdrawn")}
            >
              <span>{withDrawnCount}</span>
              <span> Withdrawn</span>
            </span>
            <span
              className="clear-filter-btn"
              onClick={() => onClickStatusFilter(null)}
            >
              Clear Filter
            </span>
          </div>
        </div>
        <div className="buttons-div">
          <button
            className="ul-button -app-primary -medium -icon add-new-btn cert-action-button"
            aria-label="add"
            onClick={() => onClickAddNewCert("displayAddNewCertModal")}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span> Add New</span>
          </button>

          <div className="export-div">
            <span> Export</span>
            <button
              className="ul-button -small -icon export-btn"
              onClick={onClickExportButton}
              title="If no records are selected, all records on the current page will be exported."
            >
              <FontAwesomeIcon icon={faCaretDown} />
            </button>
          </div>

          <OverlayPanel
            ref={exportOp}
            className={`${classes["export-overlay-panel"]}`}
          >
            <div>
              <button
                className={`ul-button -medium ${classes["export-buttons"]}`}
                onClick={onClickExportListView}
              >
                Export List View
              </button>
            </div>
            <div>
              <button
                className={`ul-button -medium ${classes["export-buttons"]}`}
                onClick={onClickExportAllColumns}
              >
                Export All Columns
              </button>
            </div>
          </OverlayPanel>

          {/* <button
            className={`ul-button -app-tertiary -medium -icon  process-btn cert-action-button ${
              isWithDrawnBtnDisabled ? "process-btn-disabled" : ""
            }`}
          >
            <span className="material-icons withdraw-icon">archive</span>
            <span className="withdraw-btn-text"> Withdraw</span>
          </button>

          <button
            className={`ul-button -app-tertiary -medium -icon  process-btn cert-action-button ${
              isSendToBtnDisabled ? "process-btn-disabled" : ""
            }`}
          >
            <FontAwesomeIcon icon={faUserTag} />
            <span> Send To</span>
          </button> */}

          <CertificateListFavorites
            favoriteName={favoriteName}
            favoriteList={favoriteList}
            onSaveFavorite={onSaveFavorite}
            onEditFavorite={onEditFavorite}
            onDeleteFavorite={onDeleteFavorite}
            onClickFavorites={onClickFavorites}
            onClearFilters={onClearFilters}
          />

          <CertificateListShowHide
            fields={columns}
            frozenColCount={frozenColCount}
            updateCertListColumns={updateCertListColumns}
            setCertListColumns={setCertListColumns}
          />
        </div>
      </div>

      <AutoTimestampModal
        isOpen={isOpenAutotimestampModal}
        setIsOpen={setIsOpenAutotimestampModal}
      ></AutoTimestampModal>
    </>
  );
};

export default CertificateListHeader;
